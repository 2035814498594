import { createAction, createReducer } from 'redux-act';
import { getDefaultPath } from "../../helpers/urlSync";
import {SERVICE_CONFIG} from "../../helpers/constants";

export function getView(width : number) {
    let newView = "MobileView";
    if (width > 1220) {
        newView = "DesktopView";
    } else if (width > 767) {
        newView = "TabView";
    }
    return newView;
}

const preKeys = getDefaultPath();
const path = process.env.NODE_ENV === 'development' ? '/' : SERVICE_CONFIG.api.context;

export declare type DefaultAppState = {
    collapsed: boolean,
    view: string,
    height: number,
    openDrawer: boolean,
    openKeys: Array<any>,
    current: Array<any>,
    baseRoute: string,
    visibleSettingDrawer: boolean
};

const defaultState: DefaultAppState = {
    collapsed: window.innerWidth <= 1220,
    view: getView(window.innerWidth),
    height: window.innerHeight,
    openDrawer: false,
    openKeys: preKeys,
    current: preKeys,
    baseRoute: path,
    visibleSettingDrawer: false
};

export const appRa = createReducer<DefaultAppState>({
}, defaultState);

export const toggleCollapsed = createAction<{}>('toggleCollapsed');
appRa.on(toggleCollapsed, (state, payload) => ({
    ...state,
    collapsed: !state.collapsed
}));

export const toggleOpenDrawer = createAction<{}>('toggleOpenDrawer');
appRa.on(toggleOpenDrawer, (state, payload) => ({
    ...state,
    openDrawer: !state.openDrawer
}));

export const toggleAll = createAction<{view?: string, height?: number, collapsed?: boolean}>('toggleAll');
appRa.on(toggleAll, (state, payload) => {
    if (state.view !== payload.view || payload.height !== state.height) {
        return {
            ...state,
            collapsed: payload.collapsed ? payload.collapsed : state.collapsed,
            view: payload.view ? payload.view : state.view,
            height:  payload.height ? payload.height : state.height
        };
    }
    return {...state}
});

export const changeOpenKeys = createAction<{openKeys: Array<any>}>('changeOpenKeys');
appRa.on(changeOpenKeys, (state, payload) => {
    return {
        ...state,
        openKeys: payload.openKeys
    }
});

export const changeCurrent = createAction<{current: Array<any>}>();
appRa.on(changeCurrent, (state, payload) => ({
        ...state,
        current: payload.current
    })
);

export const clearMenu = createAction<{}>('clearMenu');
appRa.on(clearMenu, (state, payload) => {
    return {
        ...state,
        openKeys: [],
        current: []
    }
});

export const updateSettingDrawerVisible = createAction<{visible: boolean}>('updateDrawerVisible');
appRa.on(updateSettingDrawerVisible, (state, payload) => {
    return {
        ...state,
        visibleSettingDrawer: payload.visible
    }
});