import { createAction, createReducer } from 'redux-act'
import {SERVICE_CONFIG} from "../../helpers/constants";
import {addKeyProps} from "../../helpers/utility";
import {FetchListGroupRequestModel} from "../../model/group/request.model";
import {SingleGroup} from "../../model/group/response.model";
const path = process.env.NODE_ENV === 'development' ? '/' : SERVICE_CONFIG.api.context;

export declare type DefaultGroupState = {
    listGroup: Array<any>,
    singleGroup: any,
    isLoading: boolean,
    hasData: boolean,
    total: number,
    allKeys: Array<string>,
    root: string,
};

const defaultState : DefaultGroupState = {
    listGroup: [],
    singleGroup: {},
    isLoading: false,
    hasData: false,
    total: 0,
    allKeys: [
        "key",
        "name",
        "companyId",
        "description"
    ],
    root: `${path}dashboard/user/group`,
};

export const groupRa = createReducer<DefaultGroupState>({}, defaultState);

export const requestFetchListGroup = createAction<{params: FetchListGroupRequestModel}>('requestFetchListGroup');
groupRa.on(requestFetchListGroup, (state, payload) => ({
    ...state,
    isLoading: true,
    hasData: false
}));

export const successRequestFetchListGroup = createAction<{listGroup: Array<SingleGroup>, params: any, total: number}>('successRequestFetchListGroup');
groupRa.on(successRequestFetchListGroup, (state, payload) => ({
    ...state,
    isLoading: false,
    hasData: true,
    listGroup: addKeyProps(payload.listGroup, payload.params.page, payload.params.size),
    total: payload.total
}));

export const errorRequestFetchListGroup = createAction<{}>('errorRequestFetchListGroup');
groupRa.on(errorRequestFetchListGroup, (state, payload) => ({
    ...state,
    isLoading: false,
    hasData: false
}));

export const requestDeleteGroup = createAction<{id: number, params: FetchListGroupRequestModel}>('requestDeleteGroup');
groupRa.on(requestDeleteGroup, (state, payload) => ({
    ...state,
    isLoading: true
}));

export const successRequestDeleteGroup = createAction<{}>('successRequestDeleteGroup');
groupRa.on(successRequestDeleteGroup, (state, payload) => ({
    ...state,
    isLoading: false
}));

export const errorRequestDeleteGroup = createAction<{}>('errorRequestDeleteGroup');
groupRa.on(errorRequestDeleteGroup, (state, payload) => ({
    ...state,
    isLoading: false
}));

export const editSingleGroup = createAction<{key: string, value: any}>('editSingleGroup');
groupRa.on(editSingleGroup, (state, payload) => ({
    ...state,
    singleGroup: {
        ...state.singleGroup,
        [payload.key]: payload.value
    }
}));

export const clearSingleGroup = createAction<{}>('clearSingleGroup');
groupRa.on(clearSingleGroup, (state, payload) => {
    return {
        ...state,
        singleGroup: {}
    }
});

export const requestUpdateGroup = createAction<{singleGroup: any, id: number}>('requestUpdateSingleGroup');
groupRa.on(requestUpdateGroup, (state, payload) => ({
    ...state,
    isLoading: true
}));

export const successRequestUpdateGroup = createAction<{}>('successRequestUpdateSingleGroup');
groupRa.on(successRequestUpdateGroup, (state, payload) => {
    return {
        ...state,
        isLoading: false
    }
});

export const errorRequestUpdateGroup = createAction<{}>('errorRequestUpdateGroup');
groupRa.on(errorRequestUpdateGroup, (state, payload) => {
    return {
        ...state,
        isLoading: false
    }
});

export const requestCreateGroup = createAction<{singleGroup: any}>('requestCreateGroup');
groupRa.on(requestCreateGroup, (state, payload) => {
    return {
        ...state,
        isLoading: true
    }
});

export const successRequestCreateGroup = createAction<{}>('successRequestCreateGroup');
groupRa.on(successRequestCreateGroup, (state, payload) => {
    return {
        ...state,
        isLoading: false
    }
});

export const errorRequestCreateGroup = createAction<{}>('errorRequestCreateGroup');
groupRa.on(errorRequestCreateGroup, (state, payload) => {
    return {
        ...state,
        isLoading: false
    }
});

export const requestFetchSingleGroup = createAction<{id: number}>('requestFetchSingleGroup');
groupRa.on(requestFetchSingleGroup, (state, payload) => {
    return {
        ...state,
        isLoading: true
    }
});

export const successRequestFetchSingleGroup = createAction<{singleGroup: any}>('requestFetchSingleGroup');
groupRa.on(successRequestFetchSingleGroup, (state, payload) => {
    return {
        ...state,
        isLoading: false,
        singleGroup: payload.singleGroup
    }
});


export const errorRequestFetchSingleGroup = createAction<{}>('requestFetchSingleGroup');
groupRa.on(errorRequestFetchSingleGroup, (state, payload) => {
    return {
        ...state,
        isLoading: false
    }
});