import themedefault from './themedefault';
import theme2 from './theme2';

export declare type CustomPaletteTheme = {
    primary: Array<string>,
    secondary: Array<string>,
    color: Array<string>,
    warning: Array<string>,
    success: Array<string>,
    error: Array<string>,
    grayscale: Array<string>
    text: Array<string>,
    border: Array<string>
    calendar: Array<string>
};

export declare type CustomFontTheme = {
    primary: string,
    pre: string,
};

export declare type CustomThemeConfig = {
    palette: CustomPaletteTheme,
    fonts: CustomFontTheme
};

const themes : any = {
    themedefault,
    theme2
};
export default themes;
