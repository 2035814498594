import React, {Component} from 'react';
import {Textarea as Input} from './uielements/input';
import {CheckCircleOutlined, EditOutlined} from '@ant-design/icons';

type editableComponentProps = {
  value: any,
  onChange: (itemKey: any, value: any) => void,
  itemKey: any
};


export default class EditableComponent extends Component<editableComponentProps, {value: any, editable: boolean}> {
  constructor(props: editableComponentProps) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.check = this.check.bind(this);
    this.edit = this.edit.bind(this);
    this.state = {
      value: this.props.value,
      editable: false,
    };
  }
  handleChange(event: React.BaseSyntheticEvent<HTMLElement>) {
    const value = event.target.value;
    this.setState({ value });
  }
  check() {
    this.setState({ editable: false });
    if (this.props.onChange) {
      this.props.onChange(this.props.itemKey, this.state.value);
    }
  }
  edit() {
    this.setState({ editable: true });
  }

  render() {
    const { value, editable } = this.state;
    return (
      <div className="opsNoteContent">
        {editable ? (
          <div className="opsNoteEditWrapper">
            <Input
              rows={3}
              value={value}
              onChange={this.handleChange}
              onPressEnter={this.check}
            />
            <CheckCircleOutlined  className="opsNoteEditIcon" onClick={this.check}/>
          </div>
        ) : (
          <p className="opsNoteTextWrapper" onClick={this.edit}>
            {value || ' '}
            <EditOutlined className="opsNoteEditIcon" />
          </p>
        )}
      </div>
    );
  }
}
