import Input from 'antd/lib/input';
import {
    InputGroupWrapper,
    InputPasswordWrapper,
    InputSearchWrapper,
    InputWrapper,
    TextAreaWrapper,
} from './styles/input.style';
import WithDirection from '../../settings/withDirection';

const {Search, TextArea, Group, Password} = Input;

const WDStyledInput = InputWrapper(Input);
const StyledInput = WithDirection(WDStyledInput);

const WDInputGroup = InputGroupWrapper(Group);
const InputGroup = WithDirection(WDInputGroup);

const WDInputSearch = InputSearchWrapper(Search);
const InputSearch = WithDirection(WDInputSearch);

const WDTextarea = TextAreaWrapper(TextArea);
const Textarea = WithDirection(WDTextarea);

const WDPassword = InputPasswordWrapper(Password);
const InputPassword = WithDirection(WDPassword);

export default StyledInput;
export {InputSearch, InputGroup, Textarea, InputPassword};
