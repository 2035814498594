export const servicesName = {
    group: 'group',
    auth: "auth",
    orders: "orders",
    order: "order",
    ship: "history-search",
    items: "items-plural",
};

export const SERVICE_CONFIG = {
    api: {
        timeout: 20000,
        // url: 'http://localhost:1337/api',
        url: process.env.NODE_ENV === 'production'?'http://localhost:1337/api':process.env.REACT_APP_API_URL,
        context: "/",
        internal: {
            [servicesName.auth]: 'auth/local',
            [servicesName.items]: 'items-plural',
            [servicesName.order]: 'order',
            [servicesName.ship]: 'history-search',
            [servicesName.orders]: 'orders',
            [servicesName.group]: "user/internal/group/v1/groups",
        }
    }
};

// module.exports = SERVICE_CONFIG;
