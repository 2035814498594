import {createReducer, createAction} from 'redux-act';
import { checkIsLogin } from '../../helpers/utility';
import { LoginRequestModel } from '../../model/signIn/request.model';

// export declare type DefaultAuthState = {
//     idToken : string | null,
//     isLoggedIn: boolean
// }

// const defaultState: DefaultAuthState = {
//     idToken: null,
//     isLoggedIn: false
// };

export declare type DefaultSignInState = {
  isLoggedIn: boolean,
  isLoading: boolean,
  email: string,
  password: string
}

const defaultState: DefaultSignInState = {
  isLoggedIn: checkIsLogin(),
  email: "",
  password: "",
  isLoading: false
};

// export const authRa = createReducer<DefaultAuthState>({}, defaultState);

export const signInRa = createReducer<DefaultSignInState>({}, defaultState);

export const requestLogin = createAction<LoginRequestModel>("requestLogin");
signInRa.on(requestLogin, (state, payload) => {
    return {
        ...state,
        isLoading: true
    }
});

export const successLogin = createAction<{}>("successLogin");
signInRa.on(successLogin, (state, payload) => {
    return {
        ...state,
        isLoggedIn: true,
        isLoading: false
    }
});

export const errorLogin = createAction<{}>("errorLogin");
signInRa.on(errorLogin, (state, payload) => {
    return {
        ...state,
        isLoggedIn: false,
        isLoading: false
    }
});

export const checkAuthorization = createAction<{}>('checkAuthorization');

// export const successLogin = createAction<{}>('successLogin');
// signInRa.on(successLogin, (state, payload) => {
//     return {
//         ...state,
//         isLoggedIn: true
//     }
// });

export const requestLogout = createAction<{}>('requestLogout');
signInRa.on(requestLogout, (state, payload) => {
    localStorage.removeItem('isLogin');
    return {
        ...state,
        isLoggedIn: false
    };
});
