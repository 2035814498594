const vi_object = {
  "commonLang.cancel": "Quay lại",
  "commonLang.save": "Lưu",
  "commonLang.search": "Tìm đơn",
  "commonLang.calculate": "Tính tiền",
  'common.Lang.setting': 'Cài đặt',
  "commonLang.back": "Quay lại",
  "common.lang.true": "Có",
  "common.lang.false": "Không",
  'common.lang.language': 'Chọn ngôn ngữ',
  "common.lang.submit": "Thực hiện",
  'drawer.setting.clearCache': 'Xóa cache',
  "common.lang.reset": "Làm mới",
  "sidebar.menu.user": "Quản lý chung",
  "sidebar.menu.ship.fee":"Tính phí ship",
  "sidebar.menu.ship": "Quản lý ship",
  "sidebar.menu.group": "Quản lý đơn",
  "sidebar.menu.side": "Quản lý thu chi",
  "todo.all": "Tất cả",
  "todo.uncompleted": "Chưa hoàn thành",
  "todo.completed": "Đã hoàn thành",
  "todo.placeholder": "Gõ để thêm mới công việc",
  "topbar.viewAll": "Tất cả",
  "topbar.help": "Trợ giúp",
  "topbar.logout": "Đăng xuất",
  "topbar.feedback": "Phản hồi",
  "themeSwitcher.settings": "Cài đặt",
  "antTable.title.firstName": "Tên",
  "antTable.title.lastName": "Họ",
  "antTable.title.city": "Thành phố",
  "antTable.title.street": "Đường",
  "page.signInTitle": "Operation Portal",
  "page.signInButton": "Đăng nhập",
  "page.signInRememberMe": "Ghi nhớ đăng nhập",
  "page.signIn.email": "Tên đăng nhập",
  "page.signIn.password": "Mật khẩu",
  "page.signInFacebook": "Đăng nhập với Facebook",
  "page.signInGooglePlus": "Đăng nhập với Google+",
  "page.signInForgotPass": "Quên mật khẩu",
  "page.signInCreateAccount": "Tạo mới tài khoản",
  "page.signUpTitle": "Operation Portal",
  "page.signUpButton": "Đăng ký",
  "page.signUpTermsConditions": "Tôi đồng ý với các điều kiện và điều khoản",
  "page.signUpFacebook": "Đăng ký với Facebook",
  "page.signUpGooglePlus": "Đăng ký với Google+",
  "page.signUpAlreadyAccount": "Đã có tài khoản. Đăng nhập ngay!",
  "page500.title": "500",
  "page500.subTitle": "Well, you broke the internet!",
  "page500.backButton": "Back home",
  "page404.title": "404",
  "page404.subTitle": "Sorry but we could not find the page you are looking for!",
  "page404.backButton": "Back home",
  "widget.reportswidget.label": "Sản phẩm của tuần",
  "widget.reportswidget.details": "All product of the week",
  "widget.singleprogresswidget1.label": "FmcgProduct1",
  "widget.singleprogresswidget2.label": "FmcgProduct2",
  "widget.singleprogresswidget3.label": "FmcgProduct3",
  "widget.singleprogresswidget4.label": "FmcgProduct4",
  "widget.singleprogresswidget5.label": "FmcgProduct5",
  "widget.stickerwidget1.number": "210",
  "widget.stickerwidget1.text": "Email chưa đọc",
  "widget.stickerwidget2.number": "1749",
  "widget.stickerwidget2.text": "Tài liệu đã upload",
  "widget.stickerwidget3.number": "3024",
  "widget.stickerwidget3.text": "Tổng số người vay",
  "widget.stickerwidget4.number": "5000",
  "widget.stickerwidget4.text": "Tổng số giao dịch",
  "widget.table.text": "Khách hàng mới",
  "widget.salewidget1.label": "Fmcg Product 1 Income",
  "widget.salewidget1.price": "$17000",
  "widget.salewidget2.label": "Fmcg Product 2 Income",
  "widget.salewidget2.price": "$25000",
  "widget.salewidget3.label": "Fmcg Product 3 Income",
  "widget.salewidget3.price": "$15000",
  "widget.salewidget4.label": "Fmcg Product 4 Income",
  "widget.salewidget4.price": "$12000",
  "component.exportFile.cancel": "Quay lại",
  "component.exportFile.title": "Xuất tệp tin",
  "component.exportFile.chooseField": "Chọn trường hiển thị trong tệp",
  "component.exportFile.chooseFileType": "Chọn loại tệp khi xuất",
  "component.commonForm.cancel": "Quay lại",
  "component.commonForm.submit": "Lưu lại",
  "group.page.title": "Quản lý nhóm",
  "group.actions.add": "Thêm nhóm",
  "group.actions.exportData": "Xuất dữ liệu",
  "group.key": "STT",
  "group.name": "Tên nhóm",
  "group.companyId": "Mã công ty",
  "group.description": "Mô tả",
  "group.actions.search": "Tìm kiếm",
  "group.page.new.title": "Thêm nhóm mới",
  "group.page.title.search": "Tìm kiếm",
  "group.table.title": "Danh sách nhóm",
  "group.warning.required.name": "Vui lòng nhập tên nhóm",
  "group.warning.required.description": "Vui lòng nhập mô tả",
  "group.noti.delete.success": "Xóa nhóm thành công",
  "group.noti.delete.error": "Xóa nhóm thất bại",
  "group.noti.update.success": "Cập nhật thông tin nhóm thành công",
  "group.noti.update.error": "Cập nhật thông tin nhóm thất bại",
  "group.noti.create.success": "Thêm nhóm mới thành công",
  "group.noti.create.error": "Thêm nhóm mới thất bại",
  "accountSetting.security.warning.required.email": "Hãy nhập địa chỉ email",
  "accountSetting.security.warning.required.password": "Hãy nhập mật khẩu",
  "order.id": "STT",
  "order.name": "Mã đơn",
  "order.name.search": "Tên đồ cần tìm",
  "order.customer.name.search": "Tên khách cần tìm",
  "order.date.search": "Ngày cần tìm",
  "order.customer_name": "Tên khách",
  "order.customer_phone": "Số điện thoại",
  "order.ship_address": "Địa chỉ ship",
  "order.ship_time": "Thời gian ship",
  "order.start_date": "Ngày lấy đồ",
  "order.end_date": "Ngày trả đồ",
  "order.num_date": "Số ngày thuê",
  "order.deposit": "Tiền đặt cọc",
  "order.identity": "Căn cước công dân",
  "order.status": "Trạng thái đơn",
  "order.item": "Danh sách đồ",
  "order.item_name": "Tên đồ",
  "order.size": "Tên size",
  "order.quantity": "SL size",
  "order.price": "Giá",
  "order.createdAt": "Ngày tạo",
  "order.updatedAt": "Ngày cập nhật",
  "order.total_price": "Tổng tiền",
  "order.actions.exportData": "Xuất dữ liệu",
  "order.actions.add": "Tạo mới đơn",
  "order.page.title": "Quản lý đơn",
  "order.page.title.search": "Tìm kiếm đơn",
  "order.warning.required.ship_time": "Bạn chưa nhập thời gian ship đơn",
  "order.warning.required.ship_address": "Bạn chưa nhập địa chỉ ship đơn",
  "order.warning.required.customer_name": "Bạn chưa nhập tên khách",
  "order.warning.required.customer_phone": "Bạn chưa nhập số điện thoại của khách",
  "order.warning.required.start_date": "Bạn chưa nhập ngày lấy đồ",
  "order.warning.required.payment_status": "Bạn chưa nhập trạng thái thanh toán",
  "order.page.new.title": "Tạo đơn mới",
  "order.warning.required.status": "Trạng thái đơn",
  "order.warning.required.order.name.search": "Bạn chưa nhập tên đồ",
  "order.status.input": "Nhập trạng thái đơn",
  "order.payment_status.input": "Nhập trạng thái thông tin thanh toán",
  "order.payment_status": "Trạng thái thanh toán",
  "order.page.edit.title": "Chỉnh sửa đơn",
  "order.total_quantity": "Số lượng",
  "order.ship_fee": "Tiền ship",
  "order.discount": "Chiết khấu",
  "order.note": "Ghi chú",
  "order.noti.update.status.success": "Cập nhật trạng thái đơn thành công",
  "order.noti.update.status.error": "Cập nhật trạng thái đơn không thành công",
  "order.status.search": "Trạng thái đơn",
  "order.customer.phone.search": "Số điện thoại",
  "order.payment_type": "Hình thức thanh toán",
  "order.order_type": "Loại đơn",
  "order.multi_day": "Nhân ngày",
  "order.payment_status.search": "Trạng thái thanh toán",
  "ship.page.title": "Tính tiền ship",
  "ship.source_address.calculate":"Địa chỉ gửi đồ",
  "ship.target_address.calculate":"Địa chỉ nhận đồ",
  "ship.good.type.calculate":"Loại đồ",
  "ship.hand.cash.calculate":"Nhập giá cho loại hàng hóa số 3",
  "ship.warning.required.source_address.calculate":"Bạn chưa nhập địa chỉ gửi đồ",
  "ship.warning.required.target_address.calculate":"Bạn chưa nhập địa chỉ nhận đồ",
  "ship.warning.required.good_type.calculate":"Bạn chưa nhập loại đồ",
  "ship.good.type.input":"Chọn loại đồ",
  "ship.source_address.input":"Nhập địa chỉ gửi đồ",
  "ship.target_address.input":"Nhập địa chỉ nhận đồ",
  "ship.page.title.search":"Thông tin tiền ship"
}

export default vi_object;