import React from "react";
import ImageCellView from "./imageCell";
import DeleteCell from "./deleteCell";
import EditableCell from "./editableCell";
import FilterDropdown from "./filterDropdown";
import IntlLabelMessages from "../../components/intlMessage/intlLabelMessages";
import moment, { MomentInput } from "moment";
import IntlCurrencyMessage from "../intlMessage/intlCurrencyMessage";
import { Typography } from "antd";

const DateCell = (data: MomentInput) => (
  <Typography.Text style={{ fontSize: "14px" }}>
    {moment(data).format("DD/MM/YYYY")}
  </Typography.Text>
);
const ImageCell = (src: string) => <ImageCellView src={src} />;
const LinkCell = (link: JSX.Element, href: string) => (
  <a href={href ? href : "#"}>{link}</a>
);
const TextCell = (text: string) => (
  <Typography.Text ellipsis={true} style={{ fontSize: "14px" }}>
    {text}
  </Typography.Text>
);
const BooleanCell = (boolVar: boolean) => (
  <p>
    {" "}
    {boolVar ? (
      <IntlLabelMessages id={"common.lang.true"} />
    ) : (
      <IntlLabelMessages id={"common.lang.false"} />
    )}{" "}
  </p>
);
const CurrencyCell = (amount: number) => (
  <Typography.Text style={{ fontSize: "14px" }}>
    <IntlCurrencyMessage value={amount} config={{}} />
  </Typography.Text>
);

export {
  DateCell,
  ImageCell,
  LinkCell,
  TextCell,
  CurrencyCell,
  BooleanCell,
  EditableCell,
  DeleteCell,
  FilterDropdown,
};
