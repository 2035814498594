import React, {Component} from 'react';
import {connect} from 'react-redux';
import Popover from '../../components/uielements/popover';
import IntlLabelMessages from '../../components/intlMessage/intlLabelMessages';
import userpic from '../../image/user1.png';
import TopBarDropdownWrapper from './topbarDropdown.style';
import {requestLogout} from '../../redux/auth/auth.ra';
import {updateSettingDrawerVisible} from '../../redux/app/app.ra';
import {SimpleActionCreator} from "redux-act";
import {Locale} from "../LanguageSwitcher/config";

type topBarUserProps = {
    requestLogout: SimpleActionCreator<{}>,
    updateSettingDrawerVisible: SimpleActionCreator<{visible: boolean}>,
    locale: Locale
}

class TopBarUser extends Component<topBarUserProps, {visible: boolean}> {
    constructor(props: topBarUserProps) {
        super(props);
        this.handleVisibleChange = this.handleVisibleChange.bind(this);
        this.hide = this.hide.bind(this);
        this.state = {
            visible: false,
        };
    }
    hide() {
        this.setState({ visible: false });
    }
    handleVisibleChange() {
        this.setState({ visible: !this.state.visible });
    }

    render() {
        const {requestLogout, updateSettingDrawerVisible} = this.props;
        const content = (
            <TopBarDropdownWrapper className="opsUserDropdown">
                <a className="opsDropdownLink" href="# " onClick={() => {
                    updateSettingDrawerVisible({visible: true});
                    this.hide();
                }}>
                    <IntlLabelMessages id="themeSwitcher.settings" />
                </a>
                <a className="opsDropdownLink" href="# " onClick={() => this.hide()}>
                    <IntlLabelMessages id="topbar.feedback" />
                </a>
                <a className="opsDropdownLink" href="# " onClick={() => this.hide()}>
                    <IntlLabelMessages id="topbar.help" />
                </a>
                <a className="opsDropdownLink" onClick={requestLogout} href="# ">
                    <IntlLabelMessages id="topbar.logout" />
                </a>
            </TopBarDropdownWrapper>
        );

        return (
            <Popover
                content={content}
                trigger="click"
                open={this.state.visible}
                onOpenChange={this.handleVisibleChange}
                arrowPointAtCenter={true}
                placement="bottomLeft"
            >
                <div className="opsImgWrapper">
                    <img alt="user" src={userpic} />
                    <span className="userActivity online" />
                </div>
            </Popover>
        );
    }
}
export default connect(
    null,
    {
        requestLogout,
        updateSettingDrawerVisible
    }
)(TopBarUser);
