import {language} from '../../settings';

import englishLang from '../../image/flag/uk.svg';
import vnLang from '../../image/flag/vn.svg';

export declare type LanguageId = 'english' | 'vietnamese';
export declare type Locale = 'en' | 'vi';

export declare type LanguageConfigOption = {
    languageId: LanguageId,
    locale: Locale,
    text: string,
    icon: string,
}

export declare type LanguageConfig = {
    defaultLanguage: LanguageId,
    options: Array<LanguageConfigOption>
};

const config: LanguageConfig = {
    defaultLanguage: language,
    options: [
        {
            languageId: 'english',
            locale: 'en',
            text: 'English',
            icon: englishLang,
        },
        {
            languageId: 'vietnamese',
            locale: 'vi',
            text: 'Vietnamese',
            icon: vnLang,
        },
    ],
};

export const getCurrentLanguage = (lang: LanguageId) : LanguageConfigOption => {
    let selecetedLanguage = config.options[0];
    config.options.forEach(language => {
        if (language.languageId === lang) {
            selecetedLanguage = language;
        }
    });
    return selecetedLanguage;
};
export default config;