import * as _ from "lodash";
import { isEqual } from "lodash";
import React from "react";
import { IntlShape } from "react-intl";
import IntlLabelMessages from "../components/intlMessage/intlLabelMessages";
import { menuConfig } from "../containers/Sidebar/menuConfigModel";
import {
  Cell,
  renderCell,
} from "../containers/Tables/antTables/antTables.tools";
import { isLoginItemName } from "../model/signIn/localStorage.item";

export function addingInfoToSingle(data: any, element: any) {
  Object.keys(element).forEach((singleElement) => {
    if (singleElement.length <= 1) {
      const key = element[singleElement]["name"];
      data[key] = element[singleElement]["value"];
    }
  });
  return data;
}

export const getValue = (obj: any, listKeys: Array<string>): any => {
  const [key, ...rest] = listKeys;
  if (rest.length === 0) {
    if (obj === null || obj === undefined) {
      return null;
    }
    return obj[key];
  }
  if (obj.hasOwnProperty(key)) return getValue(obj[key], rest);
  else return null;
};

export const addKeyProps = (list: Array<any>, page: number, size: number) => {
  return list.map((item, index) => {
    return {
      ...item,
      key: (page - 1) * size + index + 1,
    };
  });
};

export function clearToken() {
  localStorage.removeItem("id_token");
}

export function getToken(): any {
  try {
    const idToken: string | null = localStorage.getItem("id_token");
    return { idToken: idToken };
  } catch (err) {
    clearToken();
    return {};
  }
}

export function timeDifference(givenTime: any) {
  givenTime = new Date(givenTime);
  const milliseconds = new Date().getTime() - givenTime.getTime();
  const numberEnding = (number: number) => {
    return number > 1 ? "s" : "";
  };
  const number = (num: number) => (num > 9 ? "" + num : "0" + num);
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000);
    const years = Math.floor(temp / 31536000);
    if (years) {
      const month = number(givenTime.getUTCMonth() + 1);
      const day = number(givenTime.getUTCDate());
      const year = givenTime.getUTCFullYear() % 100;
      return `${day}-${month}-${year}`;
    }
    const days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
      if (days < 28) {
        return days + " day" + numberEnding(days);
      } else {
        const months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const month = months[givenTime.getUTCMonth()];
        const day = number(givenTime.getUTCDate());
        return `${day} ${month}`;
      }
    }
    const hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
      return `${hours} hour${numberEnding(hours)} ago`;
    }
    const minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
      return `${minutes} minute${numberEnding(minutes)} ago`;
    }
    return "a few seconds ago";
  };
  return getTime();
}

export function stringToInt(value: string, defValue = 0) {
  if (!value) {
    return 0;
  }
  return parseInt(value, 10);
}

export function stringToPosetiveInt(value: string, defValue = 0) {
  const val = stringToInt(value, defValue);
  return val > -1 ? val : defValue;
}

export function cloneKeyForTable(data: Array<any>) {
  data.forEach((item, index) => {
    item.key = item.id;
  });
  return data;
}

export function filterIsValidData(data: Array<any>) {
  return data.filter(function (item) {
    return item.isDeleted !== true;
  });
}

export const genColumns = (
  allKeys: Array<any>,
  intlPrefix: string,
  dateFormatFieldName: Array<string>,
  excludeFieldName: Array<string>,
  booleanFieldName: Array<string>,
  currencyFieldName: Array<string>,
  timeFormatFieldName: Array<string>
) => {
  let result = Array<any>();
  allKeys.forEach((key) => {
    const className =
      dateFormatFieldName && dateFormatFieldName.includes(key)
        ? "opsDateCell"
        : booleanFieldName && booleanFieldName.includes(key)
        ? "opsBooleanCell"
        : "opsTextCell";
    const cell: Cell =
      dateFormatFieldName && dateFormatFieldName.includes(key)
        ? "DateCell"
        : booleanFieldName && booleanFieldName.includes(key)
        ? "BooleanCell"
        : currencyFieldName.includes(key)
        ? "CurrencyCell"
        : "TextCell";

    if (!excludeFieldName.includes(key)) {
      result.push({
        title: <IntlLabelMessages id={`${intlPrefix}.${key}`} />,
        key: key,
        // width: "1%",
        className,
        dataIndex: key,
        textWrap: "word-break",
        align: "center",
        shouldCellUpdate: (record: any, prevRecord: any) => {
          const rowContentNotChange = isEqual(record, prevRecord);
          return rowContentNotChange ? false : true;
        },
        render: (text: string, object: any) => {
          return renderCell(object, cell, key);
        },
      });
    }
  });
  return result;
};

export const getIntlMessageAsString = (
  intl: IntlShape,
  key: string
): string => {
  const message = intl.formatHTMLMessage({ id: key });
  return typeof message === "string" ? message : key;
};

export const getOpenKeyAndSelectedKey = (
  config: Array<menuConfig>,
  recentUrl: string
): {
  openKeys: Array<string>;
  selectedKey: string;
} => {
  const singleConfig = config.filter((singleConfig) =>
    recentUrl.includes(singleConfig.key)
  )[0];
  if (singleConfig.children === undefined) {
    return {
      openKeys: [],
      selectedKey: singleConfig.key,
    };
  }

  const infoChild = getOpenKeyAndSelectedKey(singleConfig.children, recentUrl);
  return {
    openKeys: [...infoChild.openKeys, singleConfig.key],
    selectedKey: infoChild.selectedKey,
  };
};

export const headerContentTypeJson = {
  "Content-Type": "application/json",
};

export const headerCORS = {
  // "Access-Control-Allow-Origin": "*",
};

export const headerForm = {
  "Content-Type": "application/x-www-form-urlencoded",
};

export const headersDefine = {
  ...headerContentTypeJson,
  ...headerCORS,
};

export const checkIsLogin = (): boolean => {
  const isLoginParams = localStorage.getItem(isLoginItemName);
  return isLoginParams ? isLoginParams === "true" : false;
};

export const maskingOrderItem = (items: any): any => {
  const oldItem = items["order_items"];
  const newItem = oldItem.map((it: any) => {
    return {
      name: it.name,
      price: it.price,
      size: it.size,
    };
  });

  return newItem;
};

export const splitOrderItem = (response: any): any => {
  const massBody = _.omit(response, ["item"]);
  const oldItem = response["item"];
  const newItem = oldItem.map((it: any) => {
    return {
      name: it.name,
      price: it.price,
      size: it.size,
      quantity: it.quantity,
    };
  });
  massBody["item"] = newItem;
  return [massBody, oldItem];
};

export const currencyFormat = (num: number): string => {
  return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + "";
};
