import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {changeLanguage, bindIntlToReducer} from '../../redux/languageSwitcher/languageSwitcher.ra';
import config, {LanguageConfigOption, LanguageId} from './config';
import {Reducers} from "../../redux/reducers";
import {SimpleActionCreator} from "redux-act";
import {IntlShape, useIntl} from "react-intl";

type LanguageSwitcherProps = {
    language: LanguageConfigOption,
    changeLanguage: SimpleActionCreator<{ language: LanguageId}>,
    bindIntlToReducer: SimpleActionCreator<{intl: IntlShape}>
};

const LanguageSwitcher: React.FunctionComponent<LanguageSwitcherProps> = (
    {
        language,
        changeLanguage,
        bindIntlToReducer
    }
) => {
    const intl = useIntl();

    useEffect(() => {
        bindIntlToReducer({intl});
    }, [language, intl, bindIntlToReducer]);

    return (
        <div className="themeSwitchBlock">
            <div className="themeSwitchBtnWrapper">
                {config.options.map(option => {
                    const {languageId, icon} = option;
                    const customClass =
                        languageId === language.languageId
                            ? 'selectedTheme languageSwitch'
                            : 'languageSwitch';

                    return (
                        <button
                            type="button"
                            className={customClass}
                            key={languageId}
                            onClick={() => {
                                if (changeLanguage) changeLanguage({language: languageId});
                            }}
                        >
                            <img src={window.location.origin + icon} alt="flag"/>
                        </button>
                    );
                })}
            </div>
        </div>
    );
};

export default connect(
    (state: Reducers) => ({
        language: state.LanguageSwitcher.language,
    }),
    {
        changeLanguage,
        bindIntlToReducer
    }
)(LanguageSwitcher);
