import {AxiosInstance} from "axios";
import Services from "../index";

class MainServices extends Services {

    public constructor(service: [string | undefined, number, AxiosInstance]) {
        super(service)
    }

}

export default MainServices;
