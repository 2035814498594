export const isFirstLoginItemName = "isFirstLogin";
export const isLoginItemName = "isLogin";
export const isChangePassItemName = "isChangePass";
export const isVerifyItemName = "isVerify";
export const isFirstLogin = "isFirstLogin";
export const tokenItemName = "token";
export const userItemName = "user";
export const correlationIdItemName = "correlation_id";
export const kycItemName = "kyc";
export const cusIdItemName = "cusId";
export const emailItemName = "email";
export const configItemName = "configs";
