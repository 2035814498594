import React from 'react';
import {Provider} from 'react-redux';
import {history, store} from '../redux/store';
import PublicRoutes from '../routes/router';
import {ThemeProvider} from 'styled-components';
import {ConfigProvider} from 'antd';
import {IntlProvider} from 'react-intl';
import themes from '../settings/themes';
import AppLocale from '../languageProvider';
import config, {getCurrentLanguage,} from '../containers/LanguageSwitcher/config';
import {themeConfig} from '../settings';
import OpsAppStyled from './main.style';
import GlobalStyles from '../static/style/globalStyle';

const currentAppLocale =
    AppLocale[getCurrentLanguage(config.defaultLanguage || 'english').locale];

const Main = () => {
    return (
        <ConfigProvider locale={currentAppLocale.antd}>
            <IntlProvider
                locale={currentAppLocale.locale}
                messages={currentAppLocale.messages}
            >
                <ThemeProvider theme={themes[themeConfig.theme]}>
                    <OpsAppStyled>
                        <Provider store={store}>
                            <PublicRoutes history={history} />
                        </Provider>
                        <GlobalStyles/>
                    </OpsAppStyled>
                </ThemeProvider>
            </IntlProvider>
        </ConfigProvider>
    );
};

export default Main;
export { AppLocale };
