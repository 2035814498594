import React, { Component, ComponentType } from "react";
import { Route } from "react-router-dom";
import asyncComponent from "../../helpers/AsyncFunc";

const routes: Array<{
  path: string;
  component: ComponentType;
  exact?: boolean;
}> = [
  {
    path: "",
    component: asyncComponent(() => import("../Widgets")),
  },
  {
    path: "404",
    component: asyncComponent(() => import("../../containers/Page/404")),
  },
  {
    path: "500",
    component: asyncComponent(() => import("../../containers/Page/500")),
  },
  {
    path: "user/order",
    component: asyncComponent(() => import("../Order")),
  },
  {
    path: "user/order/:id",
    component: asyncComponent(() => import("../Order/singleOrder")),
  },
  {
    path: "ship/fee",
    component: asyncComponent(() => import("../Ship")),
  },
  {
    path: "ship/test",
    component: asyncComponent(() => import("../Ship/SelectAndSearch")),
  },
];

type AppRouterProps = {
  url: string;
  style?: React.CSSProperties;
};

class AppRouter extends Component<AppRouterProps, {}> {
  render() {
    const { url, style } = this.props;
    return (
      <div style={style}>
        {routes.map((singleRoute) => {
          const { path, exact, ...otherProps } = singleRoute;
          return (
            <Route
              exact={exact !== false}
              key={singleRoute.path}
              path={`${url}/${singleRoute.path}`}
              {...otherProps}
            />
          );
        })}
      </div>
    );
  }
}

export default AppRouter;
