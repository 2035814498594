import { Observable } from "rxjs";
import { servicesName, SERVICE_CONFIG } from "../../helpers/constants";
import { loadItem } from "../../helpers/sessionStorage";
import { tokenItemName } from "../../model/signIn/localStorage.item";
import { handleError, handleSuccess, init_axios } from "../utils";
import MainServices from "./index";

import { headerContentTypeJson } from "../../helpers/utility";
import { FetchListParams } from "../../model";

const main_services = new MainServices(init_axios(SERVICE_CONFIG.api.url, 20000, handleSuccess, handleError));
main_services.createEntity({
  name: servicesName.order,
  contextPath: `${SERVICE_CONFIG.api.internal[servicesName.ship]}/${SERVICE_CONFIG.api.internal.user}`
});

export const getListOrder = (params: FetchListParams) => {
  const tokens = loadItem(tokenItemName);
  const token = tokens;
  const requestParams = {
    "populate": "deep",
    'pagination[page]': params.page,
    'pagination[pageSize]': params.size,
    'sort': 'id:desc'
  }

  main_services.service.defaults.headers.common.Authorization = `Bearer ${token}`;
  return new Observable<any>(observer => {
    main_services.get(`${main_services.url}/${servicesName.orders}`, requestParams, { headers: {} })
      .then(receiveData => observer.next(receiveData))
      .catch(err => observer.error(err));
  })
}

export const calculateRoute = (data: any) => {
  console.log(data)
  const payload = data;
  const tokens = loadItem(tokenItemName);
  main_services.service.defaults.headers.common.Authorization = `Bearer ${tokens}`;
  return new Observable<any>(observer => {
    main_services.post(`${main_services.url}/${servicesName.ship}/distance`, payload, { headers: headerContentTypeJson })
      .then(receiveData => observer.next(receiveData))
      .catch(err => observer.error(err));
  })
}



export interface AddressValue {
  label: string;
  value: string;
}

// export const searchSingleAddress = (address: string): Promise<AddressValue[]> => {
//   const tokens = loadItem(tokenItemName);
//   const token = tokens;
//   const requestParams = {
//     "address": address,
//   }

//   main_services.service.defaults.headers.common.Authorization = `Bearer ${token}`;
//   return new Observable<any>(observer => {
//     main_services.get(`${main_services.url}/${servicesName.ship}/street`, requestParams, { headers: {} })
//       .then(receiveData => observer.next(receiveData))
//       .catch(err => observer.error(err));
//   })
// }

export async function fetchAddress(address: string): Promise<AddressValue[]> {
  console.log("fetching address: ", address);
  return fetch(
    `http://165.22.56.197:1337/api/history-search/street?address=${address}`,
    {
      method: "GET",
      headers: {
        "Content-type": "application/json;charset=UTF-8",
        Authorization:
          "Bearer 159c80c4c0afdf3d199be4fed2b0ffcd746c29982006767334e0f1dbe9d7c1a927d7fc5b9b52e6fe7b53acc9c6abdddd3b100e299777be7ef42a3f030725f7a0da4960ac39b6bcf13cc8268e70d4500177478bd6bd51e08d5489dd43bfa808357b7ed2cf2e580c6b3624924e459403fbd9a864a6caa54a74cdf955d54f887817",
      },
    }
  )
    .then((response) => response.json())
    .then((body) =>
      body.data.map((bd: any) => ({
        label: `${bd.title}`,
        value: `${bd.geolocation}`,
      }))
    );
}
