import {
  BooleanCell,
  DateCell,
  ImageCell,
  LinkCell,
  TextCell,
  CurrencyCell,
} from "../../../components/tables/helperCells";
import { getValue } from "../../../helpers/utility";

export declare type Cell =
  | "ImageCell"
  | "DateCell"
  | "LinkCell"
  | "BooleanCell"
  | "CurrencyCell"
  | "TextCell";

export const renderCell = (object: any, type: Cell, key: string) => {
  const value = getValue(object, key.split("."));
  switch (type) {
    case "ImageCell":
      return ImageCell(value);
    case "DateCell":
      return DateCell(value);
    case "CurrencyCell":
      return CurrencyCell(value);
    case "LinkCell":
      return LinkCell(value, value);
    case "BooleanCell":
      return BooleanCell(value);
    default:
      return TextCell(value);
  }
};
