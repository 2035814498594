import React from 'react';
import Logo from "../../components/utility/logo";
import CustomScrollBar from "../../components/utility/customScrollBar";
import SidebarWrapper from "./sidebar.style";
import {Layout} from 'antd';
import {connect} from "react-redux";
import {Reducers} from "../../redux/reducers";
import {toggleOpenDrawer} from "../../redux/app/app.ra";
import {SimpleActionCreator} from "redux-act";
import {menuList} from './additions';
import {generateMenuFromConfig} from "./sidebar.utility";
import {ThemeOptions} from "../ThemeSwitcher/config";

export declare type sidebarProps = {
    collapsed: boolean,
    customizedTheme: ThemeOptions,
    toggleOpenDrawer: SimpleActionCreator<{}>,
    openDrawer: boolean,
    url: string,
    height: number
};

const Sidebar = ({collapsed, customizedTheme, toggleOpenDrawer, openDrawer, height, url} : sidebarProps) => {

    const collapsedSidebar = collapsed && !openDrawer;

    const styleSider = {
        backgroundColor: customizedTheme.backgroundColor
    };

    const onMouseEnterSidebar = () => {
        if (!openDrawer) {
            toggleOpenDrawer({});
        }
    };

    const onMouseLeaveSidebar = () => {
        if (openDrawer === true) {
            toggleOpenDrawer({});
        }
    };

    return (
        <SidebarWrapper>
            <Layout.Sider
                trigger={null}
                collapsible={true}
                collapsed={collapsedSidebar}
                style={styleSider}
                onMouseEnter={onMouseEnterSidebar}
                onMouseLeave={onMouseLeaveSidebar}
                width={240}
                className='opsSidebar'
            >
                <Logo collapsed={collapsedSidebar}/>
                <CustomScrollBar
                    style={{height: height - 70}}
                >
                    {
                        generateMenuFromConfig(menuList, url)
                    }
                </CustomScrollBar>
            </Layout.Sider>
        </SidebarWrapper>
    )
};

export default connect((state: Reducers) => ({
    collapsed: state.App.collapsed,
    openDrawer: state.App.openDrawer,
    customizedTheme: state.ThemeSwitcher.sidebarTheme,
    height: state.App.height
}), {
    toggleOpenDrawer
})(Sidebar);
