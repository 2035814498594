const options = [
    {
        key: 'users',
        label: 'sidebar.users',
        leftIcon: 'ion-android-settings',
    },
    {
        key: 'transaction',
        label: 'sidebar.transaction',
        leftIcon: 'ion-cash',
    },
    {
        key: 'configuration',
        label: 'sidebar.configuration',
        leftIcon: 'ion-ios-gear',
    }
];
export default options;
