import React from 'react';
import Scrollbar, {ScrollbarProps} from 'react-smooth-scrollbar';

const CustomScrollbar =  ({ style, children, className }: ScrollbarProps & {children: JSX.Element}) => (
  <Scrollbar
    className={className}
    style={style}
    continuousScrolling={true}
  >
    {children}
  </Scrollbar>
);

export default CustomScrollbar;