import {createAction, createReducer} from 'redux-act';
import config, {
    getCurrentLanguage,
    LanguageConfigOption, LanguageId
} from "../../containers/LanguageSwitcher/config";
import {IntlShape} from "react-intl";
import {NotificationApi} from "antd/lib/notification";
import {getIntlMessageAsString} from "../../helpers/utility";
import {notification} from "../../components";

export declare type DefaultLanguageSwitcherState = {
    isActivated: boolean,
    language: LanguageConfigOption,
    intl: IntlShape | null
}

const defaultState : DefaultLanguageSwitcherState = {
    isActivated: false,
    language: getCurrentLanguage(config.defaultLanguage || 'english'),
    intl: null
};

export const languageSwitcherRa = createReducer<DefaultLanguageSwitcherState>({}, defaultState);

export const bindIntlToReducer = createAction<{intl: IntlShape}>('bindIntlToReducer');
languageSwitcherRa.on(bindIntlToReducer, (state, payload) => {
    return {
        ...state,
        intl: payload.intl
    };
});

export const pushLocalNotification = createAction<{message: string, description: string | null, type: keyof NotificationApi}>('pushLocalNotification');
languageSwitcherRa.on(pushLocalNotification, (state, payload) => {
    const message = state.intl ?  getIntlMessageAsString(state.intl, payload.message): payload.message;
    const description = (state.intl && payload.description) ?  getIntlMessageAsString(state.intl, payload.description): '';
    notification(payload.type, message,  description);
    return {
        ...state,
    }
});

export const switchActivation = createAction<{}>('switchActivation');
languageSwitcherRa.on(switchActivation, (state, payload) => ({
    ...state,
    isActivated: !state.isActivated
}));

export const changeLanguage = createAction<{language: LanguageId}>();
languageSwitcherRa.on(changeLanguage, (state, payload) => ({
    ...state,
    language: getCurrentLanguage(payload.language)
}));

