import React from 'react';
import {connect} from 'react-redux';
import {ConfigProvider, Layout} from 'antd';
import {IntlProvider} from 'react-intl';
import TopBar from '../TopBar/topbar';
import Sidebar from '../Sidebar/Sidebar';
import {ThemeProvider} from 'styled-components';
import WindowResizeListener from 'react-window-size-listener';
import {toggleAll, updateSettingDrawerVisible} from '../../redux/app/app.ra';
import {siteConfig} from '../../settings';
import themes from '../../settings/themes';
import {AppLocale} from '../../main/main';
import AppRouter from './app.router';
import AppStyled from './app.style';
import './global.css';
import {debounce} from 'lodash';
import {Reducers} from "../../redux/reducers";
import {SimpleActionCreator} from "redux-act";
import {Locale} from "../LanguageSwitcher/config";
import {match} from 'react-router';
import StyledSettingDrawer from "../../components/settingDrawer";

type AppProps = {
    locale: Locale,
    selectedTheme: string,
    height: number,
    match: match,
    toggleAll: SimpleActionCreator<{ collapsed?: boolean, height?: number, view?: string }>,
    updateSettingDrawerVisible: SimpleActionCreator<{visible: boolean}>,
    visibleSettingDrawer: boolean
};

const {Content, Footer} = Layout;
const App: React.FunctionComponent<AppProps> = (
    {
        locale,
        selectedTheme,
        height,
        toggleAll,
        match: {url},
        updateSettingDrawerVisible,
        visibleSettingDrawer
    }
) => {
    const currentAppLocale = AppLocale[locale];
    const appHeight = window.innerHeight;

    return (
        <ConfigProvider locale={currentAppLocale.antd}>
            <IntlProvider
                locale={currentAppLocale.locale}
                messages={currentAppLocale.messages}
            >
                <ThemeProvider theme={themes[selectedTheme]}>
                    <AppStyled>
                        <Layout style={{height: appHeight}}>
                            <WindowResizeListener
                                onResize={debounce(windowSize =>
                                    toggleAll({
                                            height: windowSize.windowHeight
                                        }
                                    ), 1000)
                                }
                            />

                            <StyledSettingDrawer
                                open={visibleSettingDrawer}
                                onClose={() => {
                                    updateSettingDrawerVisible({visible: false});
                                }}
                            />

                            <TopBar url={url}/>
                            <Layout style={{flexDirection: 'row', overflowX: 'hidden'}}>
                                <Sidebar url={url}/>
                                <Layout

                                    className="opsContentMainLayout"
                                    style={{
                                        height: height
                                    }}
                                >
                                    <Content
                                        className="opsmorphicContent"
                                        style={{
                                            padding: '70px 0 0',
                                            flexShrink: 0,
                                            background: '#f1f3f6',
                                            position: 'relative'
                                        }}
                                    >
                                        <AppRouter url={url}/>
                                    </Content>
                                    <Footer
                                        style={{
                                            background: '#ffffff',
                                            textAlign: 'center',
                                            borderTop: '1px solid #ededed'
                                        }}
                                    >
                                        {siteConfig.footerText}
                                    </Footer>
                                </Layout>
                            </Layout>
                        </Layout>
                    </AppStyled>
                </ThemeProvider>
            </IntlProvider>
        </ConfigProvider>
    );
};

const mapStateToProps = (state: Reducers) => {
    const props = {
        locale: state.LanguageSwitcher.language.locale,
        selectedTheme: state.ThemeSwitcher.changeThemes.themeName,
        height: state.App.height,
        visibleSettingDrawer: state.App.visibleSettingDrawer
    };
    return props as Pick<AppProps, keyof typeof props>;
};

const mapDispatchToProps = {
    toggleAll,
    updateSettingDrawerVisible
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
