import styled from 'styled-components';
import {palette} from "../../utilities/styledTheme";
import {borderRadius} from "../../settings/style-util";

export const SettingDrawerWrapper = styled.div`
   
   .themeSwitchBlock {
      display: inline;
   }
   
  .themeSwitchBtnWrapper {
        width: 100%;
        display: flex;
        align-items: center;
        //padding: 15px 20px;

        button {
          width: 20px;
          height: 20px;
          display: flex;
          margin: ${(props: any) => props['data-rtl'] === 'rtl' ? '0 0 0 10px' : '0 10px 0 0'};
          border: 1px solid #e4e4e4;
          outline: 0;
          padding: 0;
          background: none;
          justify-content: center;
          position: relative;
          cursor: pointer;
          ${borderRadius('3px')};

          &.languageSwitch {
            border: 0;
            width: 30px;
            height: auto;

            &.selectedTheme {
              &:before,
              &:after {
                top: 2px;
                left: ${(props: any) => props['data-rtl'] === 'rtl' ? 'inherit' : '-3px'};
                right: ${(props: any) => props['data-rtl'] === 'rtl' ? '-3px' : 'inherit'};
              }
            }
          }

          img {
            width: 100%;
          }

          &.selectedTheme {
            &:before {
              content: '';
              width: 6px;
              height: 6px;
              display: -webkit-inline-flex;
              //display: -ms-inline-flex;
              display: inline-flex;
              background-color: #e0364c;
              position: absolute;
              top: -2px;
              left: ${(props: any) => props['data-rtl'] === 'rtl' ? 'inherit' : '-2px'};
              right: ${(props: any) => props['data-rtl'] === 'rtl' ? '-2px' : 'inherit'};
              ${borderRadius('50%')};
            }

            &:after {
              content: '';
              width: 6px;
              height: 6px;
              display: -webkit-inline-flex;
              //display: -ms-inline-flex;
              display: inline-flex;
              border: 1px solid ${palette('color', 13)};
              background-color: ${palette('color', 13)};
              position: absolute;
              top: -2px;
              left: ${(props: any) => props['data-rtl'] === 'rtl' ? 'inherit' : '-2px'};
              right: ${(props: any) => props['data-rtl'] === 'rtl' ? '-2px' : 'inherit'};
              -webkit-animation: selectedAnimation 1.2s infinite ease-in-out;
              animation: selectedAnimation 1.2s infinite ease-in-out;
              ${borderRadius('50%')};
            }
          }
        }
      }
`;
