import { appRa, DefaultAppState } from './app/app.ra';
import { signInRa, DefaultSignInState } from './auth/auth.ra';
import { DefaultLanguageSwitcherState, languageSwitcherRa } from './languageSwitcher/languageSwitcher.ra';
import { DefaultThemeSwitcherState, themeSwitcherRa } from './themeSwitcher/themeSwitcher.ra';
import { DefaultGroupState, groupRa } from "./group/group.ra";
import { DefaultOrderState, orderRa } from './order/order.ra';
import { DefaultShipState, shipRa } from './ship/ship.ra';

export declare type Reducers = {
  App: DefaultAppState,
  Auth: DefaultSignInState,
  LanguageSwitcher: DefaultLanguageSwitcherState,
  ThemeSwitcher: DefaultThemeSwitcherState,
  Group: DefaultGroupState,
  Order: DefaultOrderState,
  Ship: DefaultShipState
};

const reducers = {
  App: appRa,
  Auth: signInRa,
  LanguageSwitcher: languageSwitcherRa,
  ThemeSwitcher: themeSwitcherRa,
  Group: groupRa,
  Order: orderRa,
  Ship: shipRa
};

export default reducers;