import axios, {AxiosInstance, AxiosResponse} from "axios";
import {notification} from "../components";

export const handleSuccess = (response: any) => {
    return response;
}

export const handleError = (error: any): Promise<AxiosResponse> => {
    const msgError = error.response.data.message;

    switch (error.response.status) {
        case 401:
            break;
        case 404:
            notification('error', msgError, '');
            console.log(error);
            break;
        case 400:
            notification('error', msgError, '');
            console.log(error);
            break;
        default:
            console.log(`Unknown Error ${error}`);
            break;
    }
    return Promise.reject(error)
};

export const init_axios = (url: string | undefined, time_out: number, handleSuccess: any, handleError: (error: any) => Promise<AxiosResponse>, headers?: any): [string | undefined, number, AxiosInstance] => {
    const service = axios.create({
        baseURL: url,
        timeout: time_out,
        headers
    });
    service.interceptors.response.use(handleSuccess, handleError);
    return [url, time_out, service]
    // service.defaults.headers.common.Authorization = getAuthorize();
}
