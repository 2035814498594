import {createAction, createReducer} from 'redux-act';
import config, {CustomizeThemeAttribute, getCurrentTheme, ThemeOptions} from '../../containers/ThemeSwitcher/config';

export declare type DefaultThemeSwitcherState = {
    isActivated: boolean,
    changeThemes: ThemeOptions,
    topbarTheme: ThemeOptions,
    sidebarTheme: ThemeOptions,
    layoutTheme: ThemeOptions
};

const defaultState : DefaultThemeSwitcherState = {
    isActivated: false,
    changeThemes: getCurrentTheme(
        'changeThemes',
        config.changeThemes.defaultTheme || 'themedefault'
    ),
    topbarTheme: getCurrentTheme(
        'topbarTheme',
        config.topbarTheme.defaultTheme || 'themedefault'
    ),
    sidebarTheme: getCurrentTheme(
        'sidebarTheme',
        config.sidebarTheme.defaultTheme || 'themedefault'
    ),
    layoutTheme: getCurrentTheme(
        'layoutTheme',
        config.layoutTheme.defaultTheme || 'themedefault'
    )
};

export const themeSwitcherRa = createReducer<DefaultThemeSwitcherState>({}, defaultState);

export const switchActivation = createAction<{}>('switchActivation');
themeSwitcherRa.on(switchActivation, (state, payload) => {
    return {
        ...state,
        isActivated: !state.isActivated
    }
});

export const changeTheme = createAction<{attribute: CustomizeThemeAttribute, themeName: string}>('changeTheme');
themeSwitcherRa.on(changeTheme, (state, payload) => {
    const theme = getCurrentTheme(payload.attribute, payload.themeName);
    if (payload.attribute === 'layoutTheme') {
        document.getElementsByClassName(
            'truemorphicContent'
        )[0].setAttribute('backgroundColor',theme.backgroundColor ? theme.backgroundColor : '');
    }
    return {
        ...state,
        [payload.attribute]: theme
    }
});
