import React, {Component, CSSProperties} from "react";
import {connect} from "react-redux";
import Layout from "antd/lib/layout";
import {toggleCollapsed} from "../../redux/app/app.ra";
import TopBarWrapper from "./topbar.style";
import TopBarUser from "./topbarUser";
import {Reducers} from "../../redux/reducers";
import {ThemeOptions} from "../ThemeSwitcher/config";
import {SimpleActionCreator} from "redux-act";
import {Locale} from "../LanguageSwitcher/config";

const { Header } = Layout;

type TopBarProps = {
    toggleCollapsed:  SimpleActionCreator<{}>,
    customizedTheme: ThemeOptions,
    locale: Locale,
    collapsed: boolean,
    openDrawer: boolean,
    url: string
};

class TopBar extends Component<TopBarProps, {}> {
    render() {

        const { toggleCollapsed, customizedTheme, locale } = this.props;
        const collapsed = this.props.collapsed && !this.props.openDrawer;

        const styling: CSSProperties = {
            background: customizedTheme.backgroundColor,
            position: "fixed",
            width: "100%",
            height: 70
        };

        return (
            <TopBarWrapper>
                <Header
                    style={styling}
                    className={
                        collapsed ? "opsTopBar collapsed" : "opsTopBar"
                    }
                >
                    <div className="opsLeft">
                        <button
                            className={
                                collapsed ? "triggerBtn menuCollapsed" : "triggerBtn menuOpen"
                            }
                            style={{ color: customizedTheme.textColor }}
                            onClick={toggleCollapsed}
                        />
                    </div>
                    <ul className="opsRight">
                        <li
                            onClick={() => this.setState({ selectedItem: "user" })}
                            className="opsUser"
                        >
                            <TopBarUser locale={locale} />
                        </li>
                    </ul>
                </Header>
            </TopBarWrapper>
        );
    }
}

export default connect(
    (state: Reducers) => ({
        locale: state.LanguageSwitcher.language.locale,
        customizedTheme: state.ThemeSwitcher.topbarTheme,
        collapsed: state.App.collapsed,
        openDrawer: state.App.openDrawer,
    }),
    { toggleCollapsed }
)(TopBar);
