import moment from 'moment';
import { createAction, createReducer } from 'redux-act';
import { single } from 'rxjs/operators';
import { SERVICE_CONFIG } from '../../helpers/constants';
import { checkIsLogin, splitOrderItem } from '../../helpers/utility';
import { FetchListGroupRequestModel, SearchListOrderRequestModel } from '../../model/order/request.model';
import { Datum } from '../../model/order/response.model';

const path = process.env.NODE_ENV === "development" ? "/" : SERVICE_CONFIG.api.context;

export declare type DefaultOrderState = {
  isLoggedIn: boolean,
  listOrder: Array<any>,
  singleOrder: any,
  orderItem: any,
  searchComeBack: boolean,
  paramComeBack: any,
  isLoading: boolean,
  hasData: boolean,
  total: number,
  allKeys: Array<string>,
  root: string,
  statusStyle: any,
  priceStatusStyle: any
}

const defaultState: DefaultOrderState = {
  isLoggedIn: checkIsLogin(),
  listOrder: [],
  singleOrder: {},
  orderItem: {},
  paramComeBack: "/dashboard/user/order?page=1&name=&customer_name=&status=&payment_status=&customer_phone=&from_date=&to_date=&size=10",
  isLoading: false,
  searchComeBack: false,
  hasData: false,
  total: 0,
  statusStyle: {
    marginRight: "10px",
    borderColor: '#B2B2B2',
  },
  priceStatusStyle: {
    marginRight: "10px",
    marginLeft: "156px",
    borderColor: '#B2B2B2',
  },
  allKeys: [
    "id",
    "name",
    "customer_name",
    "customer_phone",
    "ship_address",
    "ship_time",
    "start_date",
    "end_date",
    "note",
    "deposit",
    "deposit_cash",
    "payment_type",
    "identity",
    "status",
    "payment_status",
    "item",
    "order_type",
    "multi_day",
    "createdAt",
    "updatedAt",
    "total_price",
  ],
  root: `${path}dashboard/user/order`,
};

// export const authRa = createReducer<DefaultAuthState>({}, defaultState);

export const orderRa = createReducer<DefaultOrderState>({}, defaultState);

export const requestFetchListOrder = createAction<{ params: FetchListGroupRequestModel }>("requestFetchListOrder");
orderRa.on(requestFetchListOrder, (state, payload) => {
  return {
    ...state,
    isLoading: true
  }
});

export const successGetListOrder = createAction<{ listOrder: Datum[], params: any, total: number }>("successGetListOrder");
orderRa.on(successGetListOrder, (state, payload) => {
  return {
    ...state,
    isLoggedIn: true,
    isLoading: false,
    hasData: true,
    listOrder: payload.listOrder,
    total: payload.total
  }
});

export const errorGetListOrder = createAction<{}>("errorGetListOrder");
orderRa.on(errorGetListOrder, (state, payload) => {
  return {
    ...state,
    isLoggedIn: false,
    hasData: false,
    isLoading: false
  }
});

export const requestFetchSingleOrder = createAction<{ id: any }>("requestFetchSingleOrder");
orderRa.on(requestFetchSingleOrder, (state, payload) => {
  return {
    ...state,
    isLoading: true
  }
});

export const successFetchSingleOrder = createAction<{ singleOrder: any }>("successFetchSingleOrder");
orderRa.on(successFetchSingleOrder, (state, payload) => {
  const respData = splitOrderItem(payload.singleOrder);
  const focusOrder = respData[0];
  focusOrder['start_date'] = moment.utc(focusOrder['start_date']);
  focusOrder['end_date'] = focusOrder['end_date'] !== null ? moment.utc(focusOrder['end_date']) : undefined;
  return {
    ...state,
    isLoading: false,
    singleOrder: focusOrder,
    orderItem: respData[1]
  }
});

export const errorFetchSingleOrder = createAction<{}>("errorFetchSingleOrder");
orderRa.on(errorFetchSingleOrder, (state, payload) => {
  return {
    ...state,
    isLoading: false
  }
});

export const requestCreateOrder = createAction<{ singleOrder: any }>("requestCreateOrder");
orderRa.on(requestCreateOrder, (state, payload) => {
  return {
    ...state,
    isLoading: true
  }
});

export const successRequestCreateOrder = createAction<{}>("successRequestCreateOrder");
orderRa.on(successRequestCreateOrder, (state, payload) => {
  return {
    ...state,
    isLoading: false
  }
});

export const errorRequestCreateOrder = createAction<{}>("errorRequestCreateOrder");
orderRa.on(errorRequestCreateOrder, (state, payload) => {
  return {
    ...state,
    isLoading: false
  }
});

export const editSingleOrder = createAction<{ key: string, value: any }>("editSingleOrder");
orderRa.on(editSingleOrder, (state, payload) => ({
  ...state,
  singleOrder: {
    ...state.singleOrder,
    [payload.key]: payload.value
  }
}));

export const requestUpdateOrder = createAction<{ singleOrder: any, id: number, searchComeBack: boolean }>("requestUpdateOrder");
orderRa.on(requestUpdateOrder, (state, payload) => ({
  ...state,
  isLoading: true
}));

export const successRequestUpdateOrder = createAction<{searchComeback:boolean}>("successRequestUpdateOrder");
orderRa.on(successRequestUpdateOrder, (state, payload) => {
  return {
    ...state,
    isLoading: false,
    searchComeBack: payload.searchComeback,
  }
});

export const errorRequestUpdateOrder = createAction<{}>("errorRequestUpdateOrder");
orderRa.on(errorRequestUpdateOrder, (state, payload) => {
  return {
    ...state,
    isLoading: false
  }
});

export const requestUpdateStatusOrder = createAction<{
  singleOrder: any, id: number, url: string, page: number, size: number, name: string,
  customer_name: string,
  customer_phone: string
  status: string,
  payment_status: string,
  from_date: string,
  to_date: string
}>("requestUpdateStatusOrder");
orderRa.on(requestUpdateStatusOrder, (state, payload) => ({
  ...state,
  isLoading: true
}));

export const updateUIChangeUpdateStatusOrder = createAction<{ singleOrder: any }>("updateUIChangeUpdateStatusOrder");
orderRa.on(updateUIChangeUpdateStatusOrder, (state, payload) => {
  const newStatusObj = payload.singleOrder;
  const oldState = state.listOrder;
  const newState: any = oldState.map(obj => {
    if (obj.id === payload.singleOrder.id) {
      return { ...obj, status: newStatusObj.status, payment_status: newStatusObj.payment_status };
    }
    return obj;
  });
  return {
    ...state,
    listOrder: newState,
    isLoading: false
  }
});

export const successRequestUpdateStatusOrder = createAction<{ singleOrder: any }>("successRequestUpdateStatusOrder");
orderRa.on(successRequestUpdateStatusOrder, (state, payload) => {
  return {
    ...state,
    singleOrder: payload.singleOrder.data,
    isLoading: false
  }
});

export const errorRequestUpdateStatusOrder = createAction<{}>("errorRequestUpdateStatusOrder");
orderRa.on(errorRequestUpdateStatusOrder, (state, payload) => {
  return {
    ...state,
    isLoading: false
  }
});

export const requestDeleteOrder = createAction<{ id: number, params: {} }>("requestDeleteOrder");
orderRa.on(requestDeleteOrder, (state, payload) => ({
  ...state,
  isLoading: true
}));

export const successRequestDeleteOrder = createAction<{}>("successRequestDeleteOrder");
orderRa.on(successRequestDeleteOrder, (state, payload) => ({
  ...state,
  isLoading: false
}));

export const errorRequestDeleteOrder = createAction<{}>("errorRequestDeleteOrder");
orderRa.on(errorRequestDeleteOrder, (state, payload) => ({
  ...state,
  isLoading: false
}));

export const requestSearchOrder = createAction<{ params: SearchListOrderRequestModel }>("requestSearchOrder");
orderRa.on(requestSearchOrder, (state, payload) => {
  return {
    ...state,
    isLoading: true
  }
});

export const successRequestSearchOrder = createAction<{ listOrder: Datum[], params: any, total: number }>("successRequestSearchOrder");
orderRa.on(successRequestSearchOrder, (state, payload) => {
  return {
    ...state,
    isLoggedIn: true,
    isLoading: false,
    hasData: true,
    listOrder: payload.listOrder,
    total: payload.total
  }
});

export const errorRequestSearchOrder = createAction<{}>("errorRequestSearchOrder");
orderRa.on(errorRequestSearchOrder, (state, payload) => {
  return {
    ...state,
    isLoggedIn: false,
    hasData: false,
    isLoading: false
  }
});

export const checkAuthorization = createAction<{}>('checkAuthorization');

// export const successLogin = createAction<{}>('successLogin');
// orderRa.on(successLogin, (state, payload) => {
//     return {
//         ...state,
//         isLoggedIn: true
//     }
// });

export const requestLogout = createAction<{}>('requestLogout');
orderRa.on(requestLogout, (state, payload) => {
  localStorage.removeItem('isLogin');
  return {
    ...state,
    isLoggedIn: false
  };
});

export const updateSearchInfo = createAction<{searchComeBack: boolean, paramComeBack: any}>('updateSearchInfo');
orderRa.on(updateSearchInfo, (state, payload) => {
  return {
    ...state,
    searchComeBack: payload.searchComeBack,
    paramComeBack: payload.paramComeBack,
    isLoggedIn: false
  };
});
