import React from "react";
import {FormatNumberOptions, useIntl} from "react-intl";

interface IntlCurrencyMessageProps {
    value: number,
    config: FormatNumberOptions
}

const IntlCurrencyMessage: React.FC<IntlCurrencyMessageProps> = ({value, config}) => {
    const intl = useIntl();
    return <span> {intl.formatNumber(value, config)} </span>;
};

export default IntlCurrencyMessage;
