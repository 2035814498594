import { push } from 'react-router-redux';
import { combineEpics, Epic, ofType } from "redux-observable";
import { of } from 'rxjs';
import { catchError, mergeMap, pluck } from 'rxjs/operators';
import { ListOrderResponseModel, SingleOrderResponseModel } from "../../model/order/response.model";
import { createOrder, getListOrder, getSingleOrder, updateSingleOrder, updateSingleStatusOrder, searchOrder } from "../../services/main/order";
import { pushLocalNotification } from "../languageSwitcher/languageSwitcher.ra";
import { Reducers } from "../reducers";
import { errorFetchSingleOrder, errorGetListOrder, errorRequestCreateOrder, errorRequestUpdateOrder, errorRequestUpdateStatusOrder, requestCreateOrder, requestFetchListOrder, requestFetchSingleOrder, requestUpdateOrder, requestUpdateStatusOrder, successFetchSingleOrder, successGetListOrder, successRequestCreateOrder, successRequestUpdateOrder, successRequestUpdateStatusOrder, requestSearchOrder, successRequestSearchOrder, errorRequestSearchOrder, updateUIChangeUpdateStatusOrder } from './order.ra';
// const path = process.env.NODE_ENV === 'development' ? '/' : SERVICE_CONFIG.api.context;

const fetchListOrderEpic: Epic = action$ => action$.pipe(
  ofType(requestFetchListOrder.getType()),
  mergeMap(
    action => getListOrder({ ...action.payload.params }).pipe(
      pluck("data"),
      mergeMap(
        (data: ListOrderResponseModel) => {
          return of(
            successGetListOrder({
              listOrder: data.data,
              total: data.meta.pagination.total,
              params: action.payload.params
            }),
          )
        }
      ),
      catchError(
        err => of(
          errorGetListOrder({})
        )
      )
    )
  )
);

const searchListOrderEpic: Epic = action$ => action$.pipe(
  ofType(requestSearchOrder.getType()),
  mergeMap(
    action => searchOrder({ ...action.payload.params }).pipe(
      pluck("data"),
      mergeMap(
        (data: ListOrderResponseModel) => {
          return of(
            successRequestSearchOrder({
              listOrder: data.data,
              total: data.meta.pagination.total,
              params: action.payload.params
            }),
          )
        }
      ),
      catchError(
        err => of(
          errorRequestSearchOrder({})
        )
      )
    )
  )
);

const fetchSingleOrderEpic: Epic = action$ => action$.pipe(
  ofType(requestFetchSingleOrder.getType()),
  mergeMap(
    action => getSingleOrder(action.payload.id).pipe(
      pluck("data"),
      mergeMap(
        (data: SingleOrderResponseModel) => {
          return of(
            successFetchSingleOrder({
              singleOrder: data,
            }),
          )
        }
      ),
      catchError(
        err => of(
          errorFetchSingleOrder({})
        )
      )
    )
  )
);

const createOrderEpic: Epic = action$ => action$.pipe(
  ofType(requestCreateOrder.getType()),
  mergeMap(
    (action) => createOrder(action.payload).pipe(
      pluck("data"),
      mergeMap(
        (data: any) => {
          return of(
            successRequestCreateOrder({}),
            push(`/dashboard/user/order?page=1`)
          )
        }
      ),
      catchError(
        err => of(
          errorRequestCreateOrder({})
        )
      )
    )
  )
);

const updateSingleOrderEpic: Epic<any, any, Reducers> = (action$, state$) => action$.pipe(
  ofType(requestUpdateOrder.getType()),
  mergeMap(
    (action) => updateSingleOrder(action.payload.id, action.payload.singleOrder).pipe(
      mergeMap(
        (data) => of(
          successRequestUpdateOrder({ searchComeback: action.payload.searchComeBack }),
          // pushLocalNotification({ type: "success", message: "group.noti.update.success", description: null }),
          push(`${state$.value.Order.paramComeBack}`)
        )
      ),
      catchError(
        err => of(
          errorRequestUpdateOrder({}),
          pushLocalNotification({ type: "error", message: "group.noti.update.error", description: null }),
        )
      )
    )
  )
);

const updateSingleStatusOrderEpic: Epic<any, any, Reducers> = (action$, state$) => action$.pipe(
  ofType(requestUpdateStatusOrder.getType()),
  mergeMap(
    (action) => updateSingleStatusOrder(action.payload.id, action.payload.singleOrder).pipe(
      mergeMap(
        (data) => of(
          successRequestUpdateStatusOrder({ singleOrder: data }),
          pushLocalNotification({ type: "success", message: "Cập nhật trạng thái đơn thành công", description: null }),
          updateUIChangeUpdateStatusOrder({ singleOrder: data.data.data })
        )
      ),
      catchError(
        err => of(
          errorRequestUpdateStatusOrder({}),
          pushLocalNotification({ type: "error", message: "Cập nhật trạng thái đơn thất bại", description: null }),
        )
      )
    )
  )
);


export const orderEpics = combineEpics(
  fetchListOrderEpic,
  fetchSingleOrderEpic,
  createOrderEpic,
  updateSingleOrderEpic,
  updateSingleStatusOrderEpic,
  searchListOrderEpic
  // requestLoginEpics,
  // requestLogoutEpic,
  // successLoginEpic,
  // errorLoginEpic,
  // checkAuthorizationEpic
);
