import { LanguageId } from "../containers/LanguageSwitcher/config";

const URLObject = {
  apiUrl: "http://truemoney.com/api/",
};

const siteConfig = {
  siteName: "AmazingLeo",
  siteIcon: "ion-earth",
  footerText: "AmazingLeo ©2022 Created by Leo",
};

const themeConfig = {
  topbar: "themedefault",
  sidebar: "themedefault",
  layout: "themedefault",
  theme: "themedefault",
};

const language: LanguageId = "vietnamese";

const firebaseConfig = {
  apiKey: "AIzaSyCFkWft-qmY5nvyCvxABBghv2ZQAnEC6Cg",
  authDomain: "truemoney-lending-47bdb.firebaseapp.com",
  databaseURL: "https://truemoney-lending-47bdb.firebaseio.com",
  projectId: "truemoney-lending-47bdb",
  storageBucket: "",
  messagingSenderId: "897292962860",
};

export { siteConfig, themeConfig, language, firebaseConfig };

export default URLObject;
