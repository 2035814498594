import styled from 'styled-components';
import {Button, Divider, Drawer} from "antd";
import {DrawerProps} from 'antd/lib/drawer';
import {SettingOutlined, ClearOutlined} from '@ant-design/icons';
import IntlLabelMessages from '../intlMessage/intlLabelMessages';
import React from "react";
import LanguageSwitcher from "../../containers/LanguageSwitcher";
import {SettingDrawerWrapper} from "./settingDrawer.style";

interface SettingDrawerProps extends DrawerProps {
}

const SettingDrawer: React.FC<SettingDrawerProps> = ({...rest}) => {
    const footerContent: JSX.Element = (
        <span style={{fontSize: 10}}>
                <p> Amazing Leo ©2022 </p>
                <p> Created by Leo VietNam </p>
            </span>
    );

    return (
            <Drawer
                title={<span> <SettingOutlined/> <IntlLabelMessages id={'common.Lang.setting'}/> </span>}
                closable={false}
                placement={'right'}
                footer={footerContent}
                {...rest}
            >

                <SettingDrawerWrapper>
                        <h3> <IntlLabelMessages id={'common.lang.language'}/> </h3> <LanguageSwitcher/>
                    <Divider/>
                        <h3> <IntlLabelMessages id={'drawer.setting.clearCache'}/> </h3>
                        <Button block={true} onClick={() => localStorage.clear()}> <ClearOutlined /> Clear </Button>
                </SettingDrawerWrapper>
            </Drawer>
    )
};

const StyledSettingDrawer = styled(SettingDrawer)`

`;

export default StyledSettingDrawer;