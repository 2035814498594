import React, { ComponentType } from "react";
import { Redirect, Route } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { connect } from "react-redux";
import { asyncComponent } from "../helpers/asyncImport";
import App from "../containers/App/app";
import { History } from "history";
import { Reducers } from "../redux/reducers";
import { SERVICE_CONFIG } from "../helpers/constants";
const path =
  process.env.NODE_ENV === "development" ? "/" : SERVICE_CONFIG.api.context;

type RestrictRouteProps = {
  component: ComponentType<any>;
  isLoggedIn: boolean;
  path: string;
};

const RestrictedRoute = ({
  component: Component,
  isLoggedIn,
  ...rest
}: RestrictRouteProps) => (
  <Route
    {...rest}
    render={(props) =>
      isLoggedIn || sessionStorage.getItem("isLogin") ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: `${path}signin`,
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

type PublicRoutesProps = {
  history: History;
  isLoggedIn: boolean;
};

const PublicRoutes = ({ history, isLoggedIn }: PublicRoutesProps) => {
  return (
    <ConnectedRouter history={history}>
      <div>
        <Route
          exact
          path={`${path}404`}
          component={asyncComponent(() => import("../containers/Page/404"))}
        />
        <Route
          exact
          path={`${path}500`}
          component={asyncComponent(() => import("../containers/Page/500"))}
        />
        <Route
          exact
          path={`${path}`}
          component={asyncComponent(() => import("../containers/Page/signin"))}
        />
        <Route
          exact
          path={`${path}signin`}
          component={asyncComponent(() => import("../containers/Page/signin"))}
        />
        <RestrictedRoute
          path={`${path}dashboard`}
          component={App}
          isLoggedIn={isLoggedIn}
        />
      </div>
    </ConnectedRouter>
  );
};

export default connect((state: Reducers) => ({
  isLoggedIn: state.Auth.isLoggedIn,
  path: state.App.baseRoute,
}))(PublicRoutes);
