import React from "react";
import {Menu} from 'antd';
import {menuConfig} from "./menuConfigModel";
import IntlLabelMessages from "../../components/intlMessage/intlLabelMessages";
import {Link} from "react-router-dom";

const genMenuItem = (config: menuConfig, baseUrl: string): JSX.Element => (
    <Menu.Item key={config.key}>
        <Link to={`${baseUrl}/${config.key}`}>
            <span className="ops-menu-item">
                <i className={config.leftIcon} style={{marginRight: 10}}/>
                <span className="nav-text">
                    <IntlLabelMessages id={config.label}/>
                </span>
            </span>
        </Link>
    </Menu.Item>
);

const genSubmenu = (config: menuConfig, baseUrl: string): JSX.Element => (
    <Menu.SubMenu
        key={config.key}
        title={
            <span className="nav-text">
                <i className={config.leftIcon} style={{marginRight: 10}} />
                <span className='nav-text'> <IntlLabelMessages id={config.label}/></span>
            </span>
        }
    >
        {
            config.children && config.children.length > 0 ? generateMenu(config.children, baseUrl) : null
        }
    </Menu.SubMenu>
);

const generateMenu = (config: Array<menuConfig>, baseUrl: string): Array<JSX.Element> => {
    return config.map((singleOption) : JSX.Element => {
        return singleOption.children ? genSubmenu(singleOption, baseUrl) : genMenuItem(singleOption, baseUrl)
    });
};

export const generateMenuFromConfig = (config: Array<menuConfig>, baseUrl: string): JSX.Element => {
    return (
        <Menu
            theme={'dark'}
            mode={'inline'}
            // selectedKeys={['user/group']}
            // openKeys={['user']}
        >
            {
                generateMenu(config, baseUrl)
            }
        </Menu>
    );
};
