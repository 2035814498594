import notification from './interaction/notification';
import {NotificationApi} from 'antd/lib/notification';

const createNotification = (type: keyof NotificationApi, message: string | JSX.Element, description: string | JSX.Element) => {
  const notificationArgs :  any = {
    message,
    description
  };
  if (type !== 'close' && type !== 'destroy') notification[type](notificationArgs);
};
export default createNotification;
