import {AxiosInstance, AxiosRequestConfig} from "axios";
import {Observable} from "rxjs";

export declare type SingleEndPoint = {
    getAll: (params: any, config?: AxiosRequestConfig) => Observable<any>,
    getOne: (id: number, config?: AxiosRequestConfig) => Observable<any>,
    create: (toCreate: any, config?: AxiosRequestConfig) => Observable<any>,
    update: (id: number, toUpdate: any, config?: AxiosRequestConfig) => Observable<any>,
    patch: (id: number, toPatch: any, config?: AxiosRequestConfig) => Observable<any>,
    delete: (id: number, config?: AxiosRequestConfig) => Observable<any>
};

class Services {
    readonly url: string | undefined;
    readonly endPoints: {
        [key: string]: SingleEndPoint
    }

    service: AxiosInstance

    constructor(service: [string | undefined, number, AxiosInstance]) {
        this.url = service[0];
        this.endPoints = {};
        this.service = service[2];
    }

    createEntity(entity: { name: string, contextPath: string }) {
        const {name} = entity;
        this.endPoints[name] = this.createRXCRUDEndpoints(entity);
    }

    createRXCRUDEndpoints({name, contextPath}: { name: string, contextPath: string }): SingleEndPoint {
        const resourceURL = `${this.url}/${contextPath}`;

        return {
            getAll: (params: any, config: AxiosRequestConfig = {}) => {
                return new Observable(observer => {
                    this.service
                        .get(resourceURL, {
                            ...config,
                            params
                        })
                        .then((data: any) => observer.next(data)).catch((err: any) => observer.error(err))
                });
            },
            getOne: (id: number, config: AxiosRequestConfig = {}) => {
                return new Observable(observer => {
                    this.service
                        .get(`${resourceURL}/${id}`, config)
                        .then(data => observer.next(data.data)).catch(err => observer.error(err))
                });
            },
            create: (toCreate: any, config: AxiosRequestConfig = {}) => {
                return new Observable(observer => {
                    this.service
                        .post(resourceURL, toCreate, config)
                        .then(data => observer.next(data)).catch(err => observer.error(err))
                });
            },
            update: (id: number, toUpdate: any, config: AxiosRequestConfig = {}) => {
                return new Observable(observer => {
                    this.service
                        .put(`${resourceURL}`, {...toUpdate, id}, config)
                        .then(data => observer.next(data)).catch(err => observer.error(err))
                });
            },
            patch: (id: number, toPatch: any, config: AxiosRequestConfig = {}) => {
                return new Observable(observer => {
                    this.service
                        .patch(`${resourceURL}/${id}`, toPatch, config)
                        .then(data => observer.next(data)).catch(err => observer.error(err))
                });
            },
            delete: (id: number, config: AxiosRequestConfig = {}) => {
                return new Observable(observer => {
                    this.service
                        .delete(`${resourceURL}/${id}`, config)
                        .then(data => observer.next(data)).catch(err => observer.error(err))
                });
            }
        };
    }

    get(path: string, params: any, config: AxiosRequestConfig) {
        return this.service.get(path, {
            ...config,
            params
        });
    }

    patch(path: string, params: any, config: AxiosRequestConfig) {
        return this.service.patch(path, params, config);
    }

    put(path: string, params: any, config: AxiosRequestConfig) {
        return this.service.put(path, params, config);
    }

    post(path: string, params: any, config: AxiosRequestConfig) {
        return this.service.post(path, params, config);
    }
}

export default Services;
