import { createAction, createReducer } from 'redux-act';
import { SERVICE_CONFIG } from '../../helpers/constants';
import { checkIsLogin } from '../../helpers/utility';
import { CalculateRouteRequestModel, SearchAddressRequestModel } from '../../model/ship/request.model';
import { Datum } from '../../model/ship/response.model';

const path = process.env.NODE_ENV === "development" ? "/" : SERVICE_CONFIG.api.context;

export declare type DefaultShipState = {
  isLoggedIn: boolean,
  isLoading: boolean,
  hasData: boolean,
  root: string,
  isSourceFetch: any,
  isTargetFetch: any,
  shipFee: number
}

const defaultState: DefaultShipState = {
  isLoggedIn: checkIsLogin(),
  isLoading: false,
  hasData: false,
  isSourceFetch: false,
  isTargetFetch: false,
  shipFee: 0,
  root: `${path}dashboard/ship/fee`,
};

export const shipRa = createReducer<DefaultShipState>({}, defaultState);

export const requestSearchAddress = createAction<{ params: SearchAddressRequestModel }>("requestSearchAddress");
shipRa.on(requestSearchAddress, (state, payload) => {
  return {
    ...state,
    isLoading: true
  }
});

export const successSearchAddress = createAction<{ listOrder: Datum[] }>("successSearchAddress");
shipRa.on(successSearchAddress, (state, payload) => {
  return {
    ...state,
    isLoggedIn: true,
    isLoading: false,
    hasData: true,
    listOrder: payload.listOrder,
  }
});

export const errorSearchAddress = createAction<{}>("errorSearchAddress");
shipRa.on(errorSearchAddress, (state, payload) => {
  return {
    ...state,
    isLoggedIn: false,
    hasData: false,
    isLoading: false
  }
});

export const requestCalculateRoute = createAction<{ params: CalculateRouteRequestModel }>("requestCalculateRoute");
shipRa.on(requestCalculateRoute, (state, payload) => {
  return {
    ...state,
    isLoading: true
  }
});

export const successCalculateRoute = createAction<{ response: any }>("successCalculateRoute");
shipRa.on(successCalculateRoute, (state, payload) => {
  const data = payload.response;
  console.log(data.ship_fee)
  return {
    ...state,
    isLoading: false,
    shipFee: data.ship_fee !== undefined?data.ship_fee:0
  }
});

export const errorCalculateRoute = createAction<{}>("errorCalculateRoute");
shipRa.on(errorCalculateRoute, (state, payload) => {
  return {
    ...state,
    isLoading: false
  }
});

export const checkAuthorization = createAction<{}>('checkAuthorization');

export const requestLogout = createAction<{}>('requestLogout');
shipRa.on(requestLogout, (state, payload) => {
  localStorage.removeItem('isLogin');
  return {
    ...state,
    isLoggedIn: false
  };
});
