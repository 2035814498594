import { SERVICE_CONFIG, servicesName } from "../../helpers/constants";
import { Observable } from "rxjs";
import { tokenItemName } from "../../model/signIn/localStorage.item";
import MainServices from "../main";
import { handleError, handleSuccess, init_axios } from "../utils";
import { loadItem } from "../../helpers/sessionStorage";

import { headersDefine } from "../../helpers/utility";

const auth_services = new MainServices(init_axios(SERVICE_CONFIG.api.url, 20000, handleSuccess, handleError));
auth_services.createEntity({ name: servicesName.auth, contextPath: SERVICE_CONFIG.api.internal[servicesName.auth] });
const recentEndPoint = auth_services.endPoints[servicesName.auth];

export const observableFetchListGroup = recentEndPoint.getAll;
export const observableDeleteGroup = recentEndPoint.delete;
export const observableCreateGroup = recentEndPoint.create;
export const observableUpdateGroup = recentEndPoint.update;

export const changePasswordRequest = (data: any) => {
  const tokens = loadItem(tokenItemName);
  const token = tokens.access.token;
  auth_services.service.defaults.headers.common.Authorization = `Bearer ${token}`;
  return new Observable<any>(observer => {
    auth_services.post(`${auth_services.url}/${SERVICE_CONFIG.api.internal.auth}/${SERVICE_CONFIG.api.internal.update_password}`, data, { headers: headersDefine })
      .then(receiveData => observer.next(receiveData))
      .catch(err => {
        return observer.error(err);
      })
  });
}

export const signInRequest = (data: any) => {
  return new Observable<any>(observer => {
    auth_services.post(`${auth_services.url}/${SERVICE_CONFIG.api.internal.auth}`, data, { headers: headersDefine })
      .then(receiveData => observer.next(receiveData))
      .catch(err => observer.error(err));
  })
}



