const us_object = {
  "commonLang.cancel": "Cancel",
  'common.Lang.setting': 'Setting',
  "commonLang.save": "Save",
  "commonLang.back": "Back",
  "commonLang.search": "Tìm đơn",
  "commonLang.calculate": "Tính tiền",
  "common.lang.true": "Yes",
  'common.lang.language': 'Choose Language',
  'drawer.setting.clearCache': 'Clear Cache',
  "common.lang.false": "No",
  "common.lang.submit": "Submit",
  "common.lang.reset": "Reset",
  "sidebar.menu.group": "Quản lý đơn",
  "sidebar.menu.side": "Quản lý thu chi",
  "sidebar.menu.user": "Quản lý chung",
  "sidebar.menu.ship": "Quản lý ship",
  "sidebar.menu.ship.fee":"Tính phí ship",
  "todo.all": "All",
  "todo.uncompleted": "Uncompleted",
  "todo.completed": "Completed",
  "todo.placeholder": "Type here for add a new todo",
  "topbar.viewAll": "View all",
  "topbar.help": "Help",
  "topbar.logout": "Logout",
  "topbar.feedback": "Feedbacks",
  "themeSwitcher.settings": "Settings",
  "antTable.title.firstName": "First Name",
  "antTable.title.lastName": "Last Name",
  "antTable.title.city": "City",
  "antTable.title.street": "Street",
  "page.signInTitle": "Sign Up",
  "page.signInButton": "Sign In",
  "page.signInRememberMe": "Remember me",
  "page.signInFacebook": "Sign in with Facebook",
  "page.signInGooglePlus": "Sign in with Google+",
  "page.signInForgotPass": "Forget password",
  "page.signInCreateAccount": "Create new account",
  "page.signUpTitle": "Operation Portal",
  "page.signUpButton": "Sign Up",
  "page.signUpTermsConditions": "I agree with terms and condtions",
  "page.signUpFacebook": "Sign up with Facebook",
  "page.signUpGooglePlus": "Sign up with Google+",
  "page.signUpAlreadyAccount": "Already have an account? Sign in.",
  "page500.title": "500",
  "page500.subTitle": "Well, you broke the internet!",
  "page500.backButton": "Back home",
  "page404.title": "404",
  "page404.subTitle": "Sorry but we could not find the page you are looking for!",
  "page404.backButton": "Back home",
  "widget.reportswidget.label": "Product of the week",
  "widget.reportswidget.details": "All product of the week",
  "widget.singleprogresswidget1.label": "FmcgProduct1",
  "widget.singleprogresswidget2.label": "FmcgProduct2",
  "widget.singleprogresswidget3.label": "FmcgProduct3",
  "widget.singleprogresswidget4.label": "FmcgProduct4",
  "widget.stickerwidget1.number": "210",
  "widget.stickerwidget1.text": "Unread Email",
  "widget.stickerwidget2.number": "1749",
  "widget.stickerwidget2.text": "Contracts Upload",
  "widget.stickerwidget3.number": "3024",
  "widget.stickerwidget3.text": "Total Borrower",
  "widget.stickerwidget4.number": "5000",
  "widget.stickerwidget4.text": "Total Transaction",
  "widget.table.text": "New Customer",
  "widget.salewidget1.label": "Fmcg Product 1 Income",
  "widget.salewidget1.price": "$17000",
  "widget.salewidget2.label": "Fmcg Product 2 Income",
  "widget.salewidget2.price": "$25000",
  "widget.salewidget3.label": "Fmcg Product 3 Income",
  "widget.salewidget3.price": "$15000",
  "widget.salewidget4.label": "Fmcg Product 4 Income",
  "widget.salewidget4.price": "$12000",
  "component.exportFile.cancel": "Cancel",
  "component.exportFile.title": "Export file",
  "component.exportFile.chooseField": "Choose field display in file",
  "component.exportFile.chooseFileType": "Choose file type",
  "component.commonForm.cancel": "Back",
  "component.commonForm.submit": "Submit",
  "group.page.title": "Group manager",
  "group.actions.add": "Add group",
  "group.key": "ID",
  "group.name": "Group Name",
  "group.companyId": "Company ID",
  "group.description": "Description",
  "group.actions.search": "Search",
  "group.actions.exportData": "Export Data",
  "group.page.new.title": "Add New Group",
  "group.page.title.search": "Search",
  "group.table.title": "Group List",
  "group.warning.required.name": "Please input group name",
  "group.warning.required.description": "Please input description",
  "group.noti.delete.success": "Delete group successfully",
  "group.noti.delete.error": "Delete group error",
  "group.noti.update.success": "Update group successfully",
  "group.noti.update.error": "Update group error",
  "group.noti.create.success": "Create new group successfully",
  "group.noti.create.error": "Create new group error",
  "order.id": "STT",
  "order.name": "Mã đơn",
  "order.name.search": "Tên đồ cần tìm",
  "order.date.search": "Ngày cần tìm",
  "order.customer.name.search": "Tên khách cần tìm",
  "order.customer_name": "Tên khách",
  "order.customer_phone": "Số điện thoại",
  "order.ship_address": "Địa chỉ ship",
  "order.ship_time": "Thời gian ship",
  "order.start_date": "Ngày lấy đồ",
  "order.end_date": "Ngày trả đồ",
  "order.num_date": "Số ngày thuê",
  "order.deposit": "Tiền đặt cọc",
  "order.identity": "Căn cước công dân",
  "order.status": "Trạng thái đơn",
  "order.item": "Danh sách đồ",
  "order.item_name": "Tên đồ",
  "order.size": "Tên size",
  "order.quantity": "SL size",
  "order.price": "Giá",
  "order.createdAt": "Ngày tạo",
  "order.updatedAt": "Ngày cập nhật",
  "order.total_price": "Tổng tiền",
  "order.actions.exportData": "Xuất dữ liệu",
  "order.actions.add": "Tạo mới đơn",
  "order.page.title": "Quản lý đơn",
  "order.page.title.search": "Tìm kiếm đơn",
  "order.warning.required.ship_time": "Bạn chưa nhập thời gian ship đơn",
  "order.warning.required.ship_address": "Bạn chưa nhập địa chỉ ship đơn",
  "order.warning.required.customer_name": "Bạn chưa nhập tên khách",
  "order.warning.required.customer_phone": "Bạn chưa nhập số điện thoại của khách",
  "order.warning.required.start_date": "Bạn chưa nhập ngày lấy đồ",
  "order.warning.required.payment_status": "Bạn chưa nhập trạng thái thanh toán",
  "order.page.new.title": "Tạo đơn mới",
  "order.warning.required.status": "Trạng thái đơn",
  "order.warning.required.order.name.search": "Bạn chưa nhập tên đồ",
  "order.status.input": "Nhập trạng thái đơn",
  "order.payment_status.input": "Nhập trạng thái thông tin thanh toán",
  "order.payment_status": "Trạng thái thanh toán",
  "order.page.edit.title": "Chỉnh sửa đơn",
  "order.total_quantity": "Số lượng",
  "order.ship_fee": "Tiền ship",
  "order.discount": "Chiết khấu",
  "order.note": "Ghi chú",
  "order.noti.update.status.success": "Cập nhật trạng thái đơn thành công",
  "order.noti.update.status.error": "Cập nhật trạng thái đơn không thành công",
  "order.customer.phone.search": "Số điện thoại",
  "order.status.search": "Trạng thái đơn",
  "order.payment_type":"Hình thức thanh toán",
  "order.order_type": "Loại đơn",
  "order.multi_day": "Nhân ngày",
  "order.payment_status.search": "Trạng thái thanh toán",
  "ship.page.title": "Tính tiền ship",
  "ship.source_address.calculate":"Địa chỉ gửi đồ",
  "ship.target_address.calculate":"Địa chỉ nhận đồ",
  "ship.good.type.calculate":"Loại đồ",
  "ship.hand.cash.calculate":"Nhập giá cho loại hàng hóa số 3",
  "ship.warning.required.source_address.calculate":"Bạn chưa nhập địa chỉ gửi đồ",
  "ship.warning.required.target_address.calculate":"Bạn chưa nhập địa chỉ nhận đồ",
  "ship.warning.required.good_type.calculate":"Bạn chưa nhập loại đồ",
  "ship.good.type.input":"Chọn loại đồ",
  "ship.source_address.input":"Nhập địa chỉ gửi đồ",
  "ship.target_address.input":"Nhập địa chỉ nhận đồ",
  "ship.page.title.search":"Thông tin tiền ship"
}

export default us_object;