export const loadItem = (item: string) => {
    try {
        const serializedState = sessionStorage.getItem(item);
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (e) {
        return undefined;
    }
};

export const saveItem = (item: string, state: any) => {
    try {
        const serializedState = JSON.stringify(state);
        sessionStorage.setItem(item, serializedState);
    } catch (e) {
        console.log('saveItem error = ', e);
    }
};

export const removeItem = (item: string) => {
    try {
        sessionStorage.removeItem(item);
    } catch (e) {
        console.log('removeItem error = ', e);
    }
};
