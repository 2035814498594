import {combineEpics, Epic, ofType} from "redux-observable";
import {catchError, mergeMap, pluck} from 'rxjs/operators';
import {of} from 'rxjs';
import {requestLogin, successLogin, requestLogout, errorLogin, checkAuthorization} from './auth.ra';
import {push} from 'react-router-redux';
import {SERVICE_CONFIG} from "../../helpers/constants";
import { getToken } from "../../helpers/utility";
import { signInRequest } from "../../services/auth/auth";
import { LoginResponseModel } from "../../model/signIn/response.model";
import { saveItem } from "../../helpers/sessionStorage";
import { isLoginItemName, tokenItemName, userItemName } from "../../model/signIn/localStorage.item";

const path = process.env.NODE_ENV === 'development' ? '/' : SERVICE_CONFIG.api.context;

const requestLoginEpics: Epic = action$ => action$.pipe(
  ofType(requestLogin.getType()),
  mergeMap(
      action => signInRequest(action.payload).pipe(
          pluck("data"),
          mergeMap(
              (data: LoginResponseModel) => {
                  saveItem(isLoginItemName, true);
                  saveItem(tokenItemName, data.jwt);
                  saveItem(userItemName, data.user.username);
                  return of(successLogin({}));

              }
          ),
          catchError(
              err => of(
                  errorLogin({})
              )
          )
      )
  )
);

const successLoginEpic: Epic = action$ => action$.pipe(
  ofType(successLogin.getType()),
  mergeMap(
      action => {
          return of(
              // console.log("login success")
              push(`${path}dashboard`)
          )
      }
  )
);

const errorLoginEpic: Epic = action$ => action$.pipe(
    ofType(errorLogin.getType()),
    mergeMap(
        (action: any) => {
            return of()
        }
    )
);

const requestLogoutEpic: Epic = action$ => action$.pipe(
    ofType(requestLogout.getType()),
    mergeMap(action => {
        localStorage.removeItem('isLogin');
        return of(push('/'))
    })
);

const checkAuthorizationEpic: Epic = action$ => action$.pipe(
    ofType(checkAuthorization.getType()),
    mergeMap(action => {
        const token = getToken().get('idToken');

        if (token) {
            return of(successLogin({}));
        }
        return of();
    })
);

export const authEpics = combineEpics(
    requestLoginEpics,
    requestLogoutEpic,
    successLoginEpic,
    errorLoginEpic,
    checkAuthorizationEpic
);
