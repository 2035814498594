import React, { ComponentType, ReactElement, useEffect, useState } from "react";
import ReactPlaceholder from 'react-placeholder';

export const asyncComponent = (
  importComponent: () => Promise<any>
): ComponentType => {
  const AsyncComponent = (props: any) => {
    const [mounted, setMounted] = useState<boolean>(false);
    const [component, setComponent] = useState<ReactElement>(<div />);
    useEffect(() => {
      // Anything in here is fired on component mount.
      setMounted(true);
      (async () => {
        const { default: Component } = await importComponent();
        if (mounted) {
          setComponent(<Component {...props} />);
        }
      })();

      return () => {
        // Anything in here is fired on component unmount.
        setMounted(false);
      };
    }, [mounted, props]);

    const Component = component || <div />;

    return (
      <ReactPlaceholder type="text" rows={7} ready={Component !== null}>
        {Component}
      </ReactPlaceholder>
    );
  };

  return AsyncComponent;
};
