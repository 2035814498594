import { combineEpics, Epic, ofType } from "redux-observable";
import { of } from 'rxjs';
import { catchError, mergeMap, pluck } from 'rxjs/operators';
import { ListOrderResponseModel } from "../../model/order/response.model";
import { SearchAddressResponseModel } from '../../model/ship/response.model';
import { getListOrder } from "../../services/main/order";
import { calculateRoute } from "../../services/main/ship";
import { errorCalculateRoute, errorSearchAddress, requestCalculateRoute, requestSearchAddress, successCalculateRoute, successSearchAddress } from './ship.ra';
// const path = process.env.NODE_ENV === 'development' ? '/' : SERVICE_CONFIG.api.context;

const searchAddressEpic: Epic = action$ => action$.pipe(
  ofType(requestSearchAddress.getType()),
  mergeMap(
    action => getListOrder({ ...action.payload.params }).pipe(
      pluck("data"),
      mergeMap(
        (data: SearchAddressResponseModel) => {
          return of(
            successSearchAddress({
              listOrder: data.data,
            }),
          )
        }
      ),
      catchError(
        err => of(
          errorSearchAddress({})
        )
      )
    )
  )
);

const calculateRouteEpic: Epic = action$ => action$.pipe(
  ofType(requestCalculateRoute.getType()),
  mergeMap(
    action => calculateRoute({ ...action.payload.params }).pipe(
      pluck("data"),
      mergeMap(
        (data: ListOrderResponseModel) => {
          return of(
            successCalculateRoute({
              response: data.data
            }),
          )
        }
      ),
      catchError(
        err => of(
          errorCalculateRoute({})
        )
      )
    )
  )
);

export const shipEpics = combineEpics(
  searchAddressEpic,
  calculateRouteEpic
);
