import { Observable } from "rxjs";
import { servicesName, SERVICE_CONFIG } from "../../helpers/constants";
import { loadItem } from "../../helpers/sessionStorage";
import { tokenItemName } from "../../model/signIn/localStorage.item";
import { handleError, handleSuccess, init_axios } from "../utils";
import MainServices from "./index";
import qs from 'qs';
import { headerContentTypeJson } from "../../helpers/utility";
import { FetchListParams, SearchListParams } from "../../model";

const main_services = new MainServices(init_axios(SERVICE_CONFIG.api.url, 20000, handleSuccess, handleError));
main_services.createEntity({
  name: servicesName.order,
  contextPath: `${SERVICE_CONFIG.api.internal[servicesName.order]}/${SERVICE_CONFIG.api.internal.user}`
});

export const getListOrder = (params: FetchListParams) => {
  const tokens = loadItem(tokenItemName);
  const token = tokens;
  const requestParams = qs.stringify({
    "populate": "deep",
    "filters": {
      is_archive: {
        $eq: false,
      },
    },
    'pagination[page]': params.page,
    'pagination[pageSize]': params.size,
    'sort': 'id:desc'
  }, {
    encodeValuesOnly: true,
  });

  main_services.service.defaults.headers.common.Authorization = `Bearer ${token}`;
  return new Observable<any>(observer => {
    main_services.get(`${main_services.url}/${servicesName.orders}?${requestParams}`, {}, { headers: {} })
      .then(receiveData => observer.next(receiveData))
      .catch(err => observer.error(err));
  })
}

export const searchOrder = (params: SearchListParams) => {
  const tokens = loadItem(tokenItemName);
  const token = tokens;
  const requestParams = {
    "populate": "deep",
    'page': params.page,
    'pageSize': params.size,
    'sort': 'id:desc',
    'name': params.name,
    'customer_name': params.customer_name,
    'customer_phone': params.customer_phone,
    'status': params.status,
    'payment_status': params.payment_status,
    'from_date': params.from_date,
    'to_date': params.to_date,
    'is_archive': false
  }

  main_services.service.defaults.headers.common.Authorization = `Bearer ${token}`;
  return new Observable<any>(observer => {
    main_services.get(`${main_services.url}/${servicesName.order}/findAdvanceOrder`, requestParams, { headers: {} })
      .then(receiveData => observer.next(receiveData))
      .catch(err => observer.error(err));
  })
}

export const getSingleOrder = (id: string) => {
  const tokens = loadItem(tokenItemName);
  const token = tokens;
  main_services.service.defaults.headers.common.Authorization = `Bearer ${token}`;
  return new Observable<any>(observer => {
    main_services.get(`${main_services.url}/${servicesName.order}/findSingleOrder/${id}`, {}, { headers: headerContentTypeJson })
      .then(receiveData => observer.next(receiveData))
      .catch(err => observer.error(err));
  })
}

export const updateSingleOrder = (id: string, singleOrder: any) => {
  const tokens = loadItem(tokenItemName);
  const token = tokens;
  main_services.service.defaults.headers.common.Authorization = `Bearer ${token}`;
  return new Observable<any>(observer => {
    main_services.put(`${main_services.url}/${servicesName.order}/updateSingleOrder/${id}`, singleOrder, { headers: headerContentTypeJson })
      .then(receiveData => observer.next(receiveData))
      .catch(err => observer.error(err));
  })
}

export const updateSingleStatusOrder = (id: string, singleOrder: any) => {
  const tokens = loadItem(tokenItemName);
  const token = tokens;
  main_services.service.defaults.headers.common.Authorization = `Bearer ${token}`;
  return new Observable<any>(observer => {
    main_services.put(`${main_services.url}/${servicesName.orders}/${id}`, { data: singleOrder }, { headers: headerContentTypeJson })
      .then(receiveData => observer.next(receiveData))
      .catch(err => observer.error(err));
  })
}

export const createOrder = (data: any) => {
  console.log(data)
  const payload = data.singleOrder;
  payload['total_price'] = 0;
  const tokens = loadItem(tokenItemName);
  const token = tokens;
  main_services.service.defaults.headers.common.Authorization = `Bearer ${token}`;
  return new Observable<any>(observer => {
    main_services.post(`${main_services.url}/${servicesName.order}/add`, payload, { headers: headerContentTypeJson })
      .then(receiveData => observer.next(receiveData))
      .catch(err => observer.error(err));
  })
}
