import React, {Component} from 'react';
import Input from '../uielements/input';
import Icon from 'antd/lib/input';

type editableCellProps = {
  value: any,
  onChange: (value: any, columnsKey: any, index: any) => void,
  columnsKey: any,
  index: any
};

export default class EditableCell extends Component<editableCellProps, {editable: boolean, value: any}> {
  state = {
    value: this.props.value,
    editable: false
  };
  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState({ value });
  };
  check = () => {
    this.setState({ editable: false });
    if (this.props.onChange) {
      this.props.onChange(
        this.state.value,
        this.props.columnsKey,
        this.props.index
      );
    }
  };
  edit = () => {
    this.setState({ editable: true });
  };
  render() {
    const { value, editable } = this.state;
    return (
      <div className="opsEditData">
        {editable ? (
          <div className="opsEditDataWrapper">
            <Input
              value={value}
              onChange={this.handleChange}
              onPressEnter={this.check}
            />
            <Icon type="check" className="opsEditIcon" onClick={this.check} />
          </div>
        ) : (
          <p className="opsDataWrapper">
            {value || ' '}
            <Icon type="edit" className="opsEditIcon" onClick={this.edit} />
          </p>
        )}
      </div>
    );
  }
}
