import React, {Component} from 'react';
import PopConfirm from '../feedback/popconfirm';

export default class DeleteCell extends Component<{index: any, onDeleteCell: (index: any) => void}, {}> {
    render() {
        const {index, onDeleteCell} = this.props;
        return (
            <PopConfirm
                title="Sure to delete?"
                okText="DELETE"
                cancelText="No"
                onConfirm={() => onDeleteCell(index)}
            >
                <a href="# ">Delete</a>
            </PopConfirm>
        );
    }
}
