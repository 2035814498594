import {menuConfig} from "./menuConfigModel";


export const menuList: Array<menuConfig> = [
    {
        key: 'user',
        label: 'sidebar.menu.user',
        leftIcon: 'ion-ios-analytics',
        children:[
            {
                key: 'user/order',
                label: 'sidebar.menu.group',
                leftIcon: 'ion-ios-list-outline',
            },
            {
                key: 'user/side',
                label: 'sidebar.menu.side',
                leftIcon: 'ion-social-usd-outline',
            }
        ]
    },
    {
      key: 'ship',
      label: 'sidebar.menu.ship',
      leftIcon: 'ion-android-bicycle',
      children:[
          {
              key: 'ship/fee',
              label: 'sidebar.menu.ship.fee',
              leftIcon: 'ion-cash',
          },
      ]
  },
];
