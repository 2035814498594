import React from "react";
import { Link } from "react-router-dom";
import { siteConfig } from "../../settings";
import logo from "../../image/logo-bar-inverted.png";

const LogoWrapper = ({ collapsed }: { collapsed: boolean }) => {
  return (
    <div className="opsLogoWrapper">
      {collapsed ? (
        <div>
          <h3>
            <Link to="/dashboard">
              <i className={siteConfig.siteIcon} />
            </Link>
          </h3>
        </div>
      ) : (
        <h3>
          <Link to="/dashboard">
            <img
              className="opsLogo"
              src={logo}
              alt="logo"
              style={{ width: 144, height: 41 }}
            />{" "}
            <span style={{ fontSize: "10px" }}></span>
          </Link>
        </h3>
      )}
    </div>
  );
};

export default LogoWrapper;
